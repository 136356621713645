@import '../../styles/index.scss';

.container {
    width: 100%;
    height: 100%;
    background-color: $bgc-n;

    padding: 0 0 rem(40) 0;
    overflow: scroll;
    overflow-x: hidden;
    .tab__wrap {
        display: flex;
        width: 100%;
        height: rem(136);
        background-color: $bgc-n;
        justify-content: space-around;
        align-items: center;
        font-size: remF(32);
        color: rgba(255, 255, 255, 0.4);

        // border-bottom: rem(1) solid rgba(249, 167, 3, 100);
    }
    .active {
        color: #ffc200;
        font-weight: bold;
        &:after {
            // position: absolute;
            display: block;
            content: '';
            width: 100%;
            transform: scale(0.8);
            height: rem(5);
            background: #ffc200;
            border-radius: rem(28);
        }
    }

    .ball__wrap {
        width: 100%;
        height: rem(500);

        position: relative;
        margin: 0 auto;
        .shadow-layer {
            background-color: rgba(31, 31, 31, 0.6);
            backdrop-filter: blur(rem(24));
            height: rem(530);
            width: 100%;
            position: absolute;
            z-index: 1;
            top: rem(-30);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .login__btn {
            height: rem(88);
            width: rem(294);
            background: #ffc200;
            border-radius: rem(48);
            font-size: remF(30);
            text-align: center;
            line-height: rem(88);
        }
        .ball__bgp {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: rem(94);
            width: rem(313.6);
            height: rem(313.6);
            border: rem(1) solid rgba(255, 236, 175, 0.2);
            border-radius: 50%;

            .ball__gbp__little {
                position: absolute;
                // background-size: rem(51.68) rem(66);

                margin: auto;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: rem(102.4);
                height: rem(102.4);
                border: rem(1) solid rgba(255, 236, 175, 0.2);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: rem(51.68);
                    height: rem(66);
                }
            }
        }

        .ball__inactive__per {
            font-weight: 600;
            font-size: remF(28.8);
            color: #ffffff;
        }
        .ball__active__per {
            font-weight: 600;
            font-size: remF(28.8);
        }
        .ball__active {
            background-color: #ffc200;
            color: #1f1f1f;
        }
        .ball__inactive {
            background: radial-gradient(
                50% 50% at 50% 50%,
                #927e40 0%,
                #927e40 0.01%,
                rgba(146, 126, 64, 0) 37.5%
            );
        }
        .ball__inactive__name {
            font-weight: 300;
            font-size: remF(26);
            color: #ffc200;
        }
        .ball {
            width: rem(160);
            height: rem(160);
            border-radius: 50%;
            text-align: center;
            padding-top: rem(40);
            box-sizing: border-box;
            font-size: remF(26);
        }
        .ball__inactive__0 {
            position: absolute;
            top: rem(108);
            left: rem(150);
        }

        .ball__inactive__1 {
            position: absolute;
            top: rem(12);
            left: rem(294.5);
        }

        .ball__inactive__2 {
            position: absolute;

            top: rem(107.77);
            left: rem(439);
        }
        .ball__inactive__3 {
            position: absolute;
            top: rem(290.67);
            left: rem(194);
        }
        .ball__inactive__4 {
            position: absolute;
            top: rem(292.18);
            left: rem(394);
        }

        .ball__item__per {
            margin-top: rem(4);
            font-size: remF(36);
        }
    }
    .line_wrap {
        display: flex;
        justify-content: center;
        .line {
            width: rem(160);
            height: rem(5);
            background-color: #ffc200;
            transition: 0.2s;
        }
        .line_position_0 {
            transform: translateX(rem(-134.5));
        }
        .line_position_1 {
            transform: translateX(rem(0));
        }
        .line_position_2 {
            transform: translateX(rem(134.5));
        }
        .line_position_3 {
            transform: translateX(rem(-100));
        }
        .line_position_4 {
            transform: translateX(rem(100));
        }
    }

    .list__wrap {
        width: 100%;
        background: #2c2c2c;
        .list__head {
            padding: rem(32) rem(48);
            width: 100%;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: remF(26);
            line-height: rem(36);
            /* identical to box height, or 140% */

            color: rgba(255, 255, 255, 0.4);
        }

        .list_container {
            &::-webkit-scrollbar {
                display: none;
            }
            padding: 0 rem(48) 0 rem(48);
            width: 100%;
            overflow: scroll;
            background: #2c2c2c;
            .list__content {
                padding: 0;
                .list_row {
                    display: inline-flex;
                    width: auto;
                    .list__item {
                        background: #2c2c2c;
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        border-radius: 50px;
                        height: rem(56);
                        font-size: rem(28);
                        margin-right: rem(32);
                        margin-bottom: rem(32);
                        display: inline-flex;
                        align-items: center;
                        background: #2c2c2c;
                        .list_icon {
                            width: rem(38);
                            height: rem(38);
                            border-radius: 50%;
                            margin: 0 rem(16);
                            background-color: #454142;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                width: rem(22);
                                height: rem(22);
                                margin-left: rem(2);
                            }
                        }
                        .list_text {
                            white-space: nowrap;
                            font-family: PingFang SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: remF(24);
                            line-height: rem(34);
                            text-transform: uppercase;
                            color: rgba(255, 255, 255, 0.6);
                            margin-right: rem(16);
                        }
                    }
                }
            }
        }
    }

    // .divide {
    //     width: rem(680);
    //     border-bottom: rem(3) solid rgba(187, 187, 187, 100);
    //     margin: 0 auto;
    // }

    // .desc {
    //     width: rem(674);
    //     margin: 0 auto rem(49);

    //     .title {
    //         margin-top: rem(30);
    //         font-size: remF(32);
    //     }

    //     .item-wrap {
    //         margin-top: rem(17);
    //     }

    //     .item {
    //         display: inline-block;
    //         padding: rem(10) rem(30);
    //         border: 1px solid #c6c6c6;
    //         background-color: #ebebeb;
    //         border-radius: rem(42);
    //         margin-top: rem(23);
    //         margin-right: rem(40);
    //         white-space: nowrap;
    //         font-size: remF(28);
    //     }
    // }

    .tips {
        margin-top: rem(32);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5d6268;
        font-size: remF(24);
    }
    .bottom-space {
        width: 100%;
        height: rem(600);
    }
}
