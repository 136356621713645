@import '../../../../styles/index.scss';

.lk-contack-info {
    width: $content-width;
    margin: 0 auto;
    padding: 0;
    height: rem(336);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    justify-items: center;
    .lk-contack-qr-codes {
        margin: rem(70) 0;
        display: flex;
        justify-items: center;
        .lk-contack-qr-code {
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
                width: rem(132);
                height: rem(132);
            }
            .lk-contack-title {
                //font-family: MiSans-Normal;
                margin-top: rem(15);
                font-size: remF(18);
                font-weight: normal;
                line-height: rem(24);
                letter-spacing: 0px;
                color: #1e2329;
                text-align: center;
            }
            .lk-contack-subtitle {
                //font-family: MiSans-Normal;
                font-size: remF(18);
                transform: scale(0.8);
                font-weight: normal;
                margin-top: rem(4);
                line-height: rem(20);
                letter-spacing: 0px;
                text-align: center;
                color: rgba($color: #474d57, $alpha: 0.7);
            }
        }
        .lk-contack-qr-code + :nth-child(2) {
            margin-left: rem(40);
        }
    }
    .lk-contack-text-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: rem(30) 0;
        .lk-contack-text-info-sub1 {
            //font-family: MiSans-Medium;
            font-size: remF(20);
            font-weight: 500;
            line-height: rem(32);
            letter-spacing: 0px;
            color: #1e2329;
        }
        .lk-contack-text-info-sub2 {
            margin-top: rem(2);
            //font-family: Barlow-Regular;
            font-size: remF(16);
            font-weight: 300;
            line-height: rem(24);
            color: #474d57;
        }
        .lk-contack-text-info-sub5 {
            //font-family: Barlow-Regular;
            margin-top: rem(14);
            font-size: remF(16);
            font-weight: 300;
            line-height: rem(24);
            letter-spacing: 0px;
            color: #474d57;
            vertical-align: middle;
            .lk-contact-email {
                vertical-align: text-top;
                width: rem(20);
                height: rem(20);
                margin-right: rem(8);
            }
        }
    }
}
.lk-contack-info-1024 {
    width: $content-width1024;
    padding: 0;
    height: rem(268);
    .lk-contack-qr-codes {
        margin: rem(40) 0;
        .lk-contack-qr-code {
            img {
                width: rem(112);
                height: rem(112);
            }
            .lk-contack-title {
                margin-top: rem(8);
                font-size: remF(16);
                line-height: rem(22);
            }
            .lk-contack-subtitle {
                margin-top: rem(2);
                font-size: remF(14);
                line-height: rem(20);
            }
        }
    }
    .lk-contack-text-info {
        margin: rem(20) 0;
        .lk-contack-text-info-sub1 {
            font-size: remF(16);
            line-height: rem(28);
        }
        .lk-contack-text-info-sub2 {
            font-size: remF(14);
            line-height: rem(20);
        }
        .lk-contack-text-info-sub5 {
            font-size: remF(14);
            line-height: rem(20);
            .lk-contact-email {
                width: rem(16);
                height: rem(16);
                margin-right: rem(6);
            }
        }
    }
}
.lk-contack-info-1024-en {
    .lk-contack-qr-codes {
        width: rem(370);
        justify-content: space-between;
        .lk-contack-qr-code {
            width: rem(180);
            .lk-contack-title {
                width: 100%;
                line-height: rem(24);
            }
        }
        .lk-contack-qr-code + :nth-child(2) {
            margin-left: rem(16);
        }
    }
    .lk-contack-text-info {
        width: rem(480);
        .lk-contack-text-info-sub2 {
            letter-spacing: -0.1px;
        }
    }
}
