@import '../../../../styles/index.scss';

.head {
    background-color: #2c2c2c;
    border-radius: rem(24);
    margin: 0 rem(48);
    padding: rem(32) rem(32);
    font-size: rem(40);

    .title {
        margin: 0 auto;
        font-size: remF(40);
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        height: rem(40);
        line-height: rem(40);
        color: #ffffff;
    }

    .foot {
        border-top: 1px solid #3e3e3e;
        margin: rem(40) auto 0;
        padding-top: rem(32);
        display: flex;
        justify-content: space-between;
    }

    .foot__item {
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-direction: column;
        // justify-content: ;
    }
    .foot__item__num {
        color: $base-n;
        font-size: remF(36);
        line-height: rem(32);
        height: rem(36);
    }

    .foot__item__label {
        font-size: remF(24);
        margin-top: rem(12);
        height: rem(24);
        line-height: rem(24);
    }
}
