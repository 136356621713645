@import '../../../../styles/index.scss';

.container {
    background-color: #FFFFFF;
    width: 100vw;
    padding-bottom: rem(120);

    .content {
        width: $content-width;
        margin: 0 auto;

        .tip {
            //font-family: MiSans-Normal;
            margin-top: rem(24);
            font-size: remF(16);
            font-weight: 300;
            line-height: rem(28);
            letter-spacing: 0px;
            color: #707a8a;
            margin-bottom: rem(40);
        }

        .tip2 {
            //font-family: MiSans-Normal;
            margin-top: rem(24);
            font-size: remF(16);
            font-weight: 300;
            line-height: rem(28);
            letter-spacing: 0px;
            color: #707a8a;
        }

        .mapimg {
            margin-top: rem(60);
            width: 100%;
            height: rem(560);
            object-fit: cover;
        }

        // .tip + :nth-child(2) {
        //     width: initial !important;
        //     height: initial !important;
        //     margin-top: rem(80) !important;
        // }
        .bottom {
            margin-top: rem(22);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .left {
                width: rem(440);
                position: relative;

                .logo {
                    position: absolute;
                    width: rem(48);
                    height: rem(48);
                    top: rem(-20);
                    transform: translateY(-100%);
                }

                .top {
                    //font-family: MiSans-Demibold;
                    font-size: remF(20);
                    font-weight: 600;
                    line-height: rem(56);
                    letter-spacing: 0px;
                    color: #1e2329;
                }

                .bottom {
                    margin-top: rem(8);
                    //font-family: MiSans-ExtraLight;
                    font-size: remF(16);
                    font-weight: 200;
                    line-height: rem(19);
                    letter-spacing: rem(10);
                    color: #424242;
                }
            }

            .right {
                width: rem(440);
                text-align: left;
                position: relative;

                .logo {
                    position: absolute;
                    width: rem(48);
                    height: rem(48);
                    top: rem(-20);
                    transform: translateY(-100%);
                }

                .top {
                    //font-family: MiSans-Light;
                    font-size: remF(20);
                    font-weight: 600;
                    line-height: rem(22);
                    color: #1e2329;
                }

                .bottom {
                    margin-top: rem(10);
                    //font-family: MiSans-ExtraLight;
                    font-size: remF(14);
                    font-weight: 200;
                    line-height: rem(17);
                    letter-spacing: rem(0.4);
                    color: #424242;
                }
            }
        }
    }
}

.containerEn {
    .content {
        .tip {
            //font-family: MiSans-Normal;
            margin-top: rem(24);
            font-size: remF(24);
            line-height: rem(40);
            font-weight: normal;
            color: #707A8A;
        }

        .tip2 {
            margin-top: rem(24);
            font-size: remF(24);
            line-height: rem(40);
            font-weight: normal;
            color: #707A8A;
        }

        .bottom {
            margin-top: rem(32);

            .left {
                position: relative;
                width: rem(596);

                .logo {
                    position: absolute;
                    width: rem(48);
                    height: rem(48);
                    top: rem(-20);
                    transform: translateY(-100%);
                }

                .top {
                    //font-family: MiSans-Demibold;
                    font-size: remF(28);
                    line-height: rem(56);
                }

                .bottom {
                    margin-top: rem(4);
                    //font-family: MiSans-ExtraLight;
                    font-size: remF(22);
                    font-weight: 300;
                    line-height: rem(32);
                    letter-spacing: 0;
                }
            }

            .right {
                width: rem(596);

                .top {
                    font-size: remF(28);
                    line-height: rem(56);
                }

                .bottom {
                    // margin-top: rem(4);
                    font-size: remF(22);
                    font-weight: 300;
                    line-height: rem(32);
                    letter-spacing: 0;
                }
            }
        }
    }
}

.container1024 {
    background-color: #fbfbfb;
    width: 100vw;
    padding-bottom: rem(80);

    .content {
        width: $content-width1024;
        margin: 0 auto;

        .tip {
            //font-family: MiSans-Normal;
            margin-top: rem(10);
            font-size: remF(14);
            font-weight: 300;
            line-height: rem(24);
            letter-spacing: 0px;
            color: #707a8a;
        }

        .tip2 {
            //font-family: MiSans-Normal;
            margin-top: rem(10);
            font-size: remF(14);
            font-weight: 300;
            line-height: rem(24);
            letter-spacing: 0px;
            color: #707a8a;
        }

        .mapimg {
            margin-top: rem(48);
            width: 100%;
            height: rem(414);
            object-fit: cover;
        }

        // .tip + :nth-child(2) {
        //     width: initial !important;
        //     height: initial !important;
        //     margin-top: rem(80) !important;
        // }
        .bottom {
            margin-top: rem(16);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .left {
                width: rem(440);
                position: relative;

                .logo {
                    position: absolute;
                    width: rem(48);
                    height: rem(48);
                    top: rem(-20);
                    transform: translateY(-100%);
                }

                .top {
                    //font-family: MiSans-Demibold;
                    font-size: remF(20);
                    font-weight: 600;
                    line-height: rem(40);
                    letter-spacing: 0px;
                    color: #1e2329;
                }

                .bottom {
                    margin-top: rem(10);
                    //font-family: MiSans-ExtraLight;
                    font-size: remF(16);
                    font-weight: 200;
                    line-height: rem(19);
                    letter-spacing: rem(10);
                    color: #424242;
                }
            }

            .right {
                text-align: left;
                width: rem(440);
                position: relative;

                .logo {
                    position: absolute;
                    width: rem(48);
                    height: rem(48);
                    top: rem(-20);
                    transform: translateY(-100%);
                }

                .top {
                    //font-family: MiSans-Light;
                    font-size: remF(20);
                    font-weight: 600;
                    line-height: rem(26);
                    color: #1e2329;
                }

                .bottom {
                    margin-top: rem(10);
                    //font-family: MiSans-ExtraLight;
                    font-size: remF(16);
                    font-weight: 200;
                    line-height: rem(19);
                    letter-spacing: rem(0.4);
                    color: #707A8A;
                }
            }
        }
    }

    .contentEn {
        .bottom {
            margin-top: rem(14);

            .left {
                position: relative;

                .logo {
                    position: absolute;
                    width: rem(48);
                    height: rem(48);
                    top: rem(-20);
                    transform: translateY(-100%);
                }

                .top {
                    //font-family: MiSans-Demibold;
                    font-size: remF(20);
                    line-height: rem(26);
                }

                .bottom {
                    margin-top: rem(10);
                    //font-family: MiSans-ExtraLight;
                    font-size: remF(16);
                    font-weight: 200;
                    line-height: rem(19);
                    letter-spacing: 0;
                    color: #707A8A;
                }
            }
        }
    }
}