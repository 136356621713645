@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.content {
    display: flex;

    overflow-x: scroll;
    box-sizing: border-box;
    justify-content: space-between;
    padding: rem(80) rem(0) rem(80) rem(32);
    background-color: #f7f7f8;
    // width: 100%;
    .item {
        width: rem(580);
        height: rem(717);
        background-color: white;
        display: flex;
        margin-right: rem(32);
        flex-direction: column;
        cursor: pointer;
        border-radius: rem(16);
        box-sizing: border-box;
        border: 1px solid #eaecef;
        .topBg {
            height: rem(320);
            border-top-right-radius: rem(16);
            border-top-left-radius: rem(16);
            width: rem(580);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            // background-attachment: i;
            position: relative;
            .topicIcon {
                height: rem(40);
                width: rem(300);
                position: absolute;
                bottom: rem(42);
                left: rem(42);
                .topicIconTitle {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    //font-family: MiSans-Semibold;
                    font-size: remF(36);
                    font-weight: 600;
                    line-height: rem(40);
                    letter-spacing: 0em;
                    color: #171819;
                    z-index: 20;
                }
                .topicIconIcon {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: rem(12);
                    width: rem(128);
                    z-index: 10;
                    // margin-top: rem(70);
                }
            }
        }
        .textContent {
            width: 100%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: rem(35) rem(32) rem(48);

            .title {
                //font-family: MiSans-Medium;
                font-size: remF(32);
                font-weight: 500;
                line-height: rem(48);
                letter-spacing: rem(0.86);
                color: #171819;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .body {
                margin-top: rem(20);
                //font-family: MiSans-Normal;
                height: auto;
                color: #707a8a;
                font-size: remF(28);
                font-weight: normal;
                line-height: rem(45);
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .more {
                //font-family: MiSans-Regular;
                font-size: remF(16);
                font-weight: normal;
                line-height: rem(20);
                letter-spacing: 0em;
                color: #171819;
                margin-top: rem(32);
                background-color: #ffd400;
                height: rem(80);
                width: rem(200);
                line-height: rem(80);
                text-align: center;
                border-radius: rem(80);
                font-size: remF(28);
                font-weight: 500;
                line-height: rem(80);
            }
            .moreEn {
                font-size: remF(28);
            }
        }
    }
}
