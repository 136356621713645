@import '../../../../styles/index.scss';

.wrapper {
    .title {
        height: rem(48);
        margin-left: rem(49);
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: remF(28);
        line-height: rem(48);
        color: #fff9ed;
    }
    .select {
        margin-left: rem(49);
        display: flex;
        margin-top: rem(14);
        margin-bottom: rem(14);
        .item {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: remF(24);
            line-height: rem(34);
            height: rem(34);
            color: #ffffff;
            margin-right: rem(48);
        }
        .selected-item {
            color: #ffc200;
        }
    }
    .data {
        margin-left: rem(49);
        height: rem(34);
        display: flex;
        margin-top: rem(14);
        margin-bottom: rem(14);
        .item {
            font-size: remF(24);
            line-height: rem(34);
            height: rem(34);
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            margin-right: rem(42);
            color: #ffffff;
        }
    }
}
