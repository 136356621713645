@import '../../styles/index.scss';


.container{
    width: 100%;
    height: 100%;
    // text-align: center;
    padding-left: rem(48);
    box-sizing: border-box;

    .textarea{
        padding: rem(30);
        box-sizing: border-box;
        margin-top: rem(64) ;
        width: rem(654);
        height: rem(500);

        background: #2C2C2C;
        border-radius: rem(24);
        border: none;
        color: rgba(255, 255, 255, 0.8);
        font-size: remF(32);
    }
    .num{
        width: rem(654);
        height: rem(70);
        text-align:right;
        font-size: rem(32);
        color: rgba(255, 255, 255, 0.8);
    }

    .submit__btn{
        // margin-left: rem(48);
        width: rem(654);
        height: rem(110);
        color: #000000;
        font-size: rem(32);
        background: #FFC200;
        border-radius: rem(24);
        line-height: rem(110);
        text-align: center;
    }

    .success__wrap{
        padding-top: rem(100);
        box-sizing: border-box;
        margin-top: rem(64) ;
        width: rem(654);
        height: rem(500);
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        font-size: remF(32);
        // background-color: #fff;
        img{
            width: rem(166.67);
            height: rem(166.67);
            margin-bottom: rem(63);
        }
        .text{
            margin-bottom: rem(10);
        }


    }

}
