@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';

.lk-mobile-contack-info {
    width: 100%;
    background-color: white;
    padding: rem(80) rem(32) 0;

    .lk-mobile-contack-footer {
        margin-top: rem(80);
        height: rem(150);
        display: flex;
        justify-content: center;
        border-top: 1px solid rgba($color: #1e2329, $alpha: 0.1);
        align-items: center;

        .lk-mobile-contack-footer-img {
            width: rem(152);
            height: rem(64);
            margin-right: rem(40);
            display: block;
        }

        .lk-mobile-contack-footer-content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .lk-mobile-contack-footer-text {
                color: #979fad;
                font-size: remF(22);
                font-weight: 500;
                line-height: rem(32);
            }

            .lk-mobile-contack-footer-text:first-child {
                margin-bottom: rem(4);
            }
        }
    }
}