@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';

.container {
    background-attachment: local;
    width: 100vw;
    background-color: #f7f7f8;
    position: relative;
    height: rem(1450);
    .bgimg {
        object-fit: cover;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        background-color: white;
    }
    .contentTop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: rem(632);
        z-index: 2;

        .content {
            padding: rem(32) rem(32) rem(16);
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: space-between;
            box-sizing: border-box;
            .bank {
                position: relative;
                width: calc((100% - 32rem / 75) / 2);
                height: rem(168);
                margin-bottom: rem(24);
                border-radius: rem(8);
                background-color: white;
            }
        }
    }
    .enDesc {
        margin: 0 rem(32);
        font-size: remF(20);
        line-height: rem(28);
        font-weight: 300;
        color: #979fad;
        white-space: pre-line;
    }
    .desc {
        margin: 0 rem(32);
        font-size: remF(24);
        line-height: rem(32);
        font-weight: 300;
        color: #979fad;
        white-space: pre-line;
    }
}
.enContainer {
    height: rem(1500);
}
:global {
    .mobile-bankDefaultImg {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
}
