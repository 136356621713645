@import '../../styles/index.scss';

.container {
    margin-top: rem(40);

    .text {
        color: rgba(76, 164, 254, 0.8);
        @include flex-center();
        text-decoration-line: underline;
        line-height: rem(40);
    }
}
