@import '../../styles/index.scss';

.container {
    width: 100%;
    // height: rem(500);
    padding-bottom: rem(50);
    background-color: $bgc-n;
    background-size:rem(750) rem(456.3);
    background-repeat: no-repeat;
    // background-position: 0 rem(-80);
    color: #FFFFFF;
    .box {
        width: 100%;
        padding: rem(90.3) rem(50) rem(48) rem(56);

        .title {
            font-size: remF(52);
            font-weight: 600;

        }

        .desc {
            margin-top: rem(40);
            color: rgba(255, 255, 255, 0.8);

            font-size: remF(26);
        }

        .notice {
            margin-top: rem(32);
            font-size: remF(26);
            color: rgba(255, 255, 255, 0.8);
        }
    }
    .detail__wrap {
        width: 100%;
        .item {
            width: rem(654);
            min-height: rem(258);
            border-radius: rem(24);
            background: #2C2C2C;
            margin-left: rem(46);
            margin-bottom: rem(32);
            box-sizing: border-box;
            padding: rem(32) rem(32) rem(50) rem(32);
            .item__head{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #FFFFFF;
                border-bottom:rem(2) solid rgba(255, 255, 255, 0.1);
                padding-bottom: rem(22);

                .name{
                    font-size: remF(32);
                }

            }
            .item__bottom{
                .value__wrap {
                    color: #FFC200;
                    margin-top: rem(22);
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    // text-align: center;
                    .item__left__key {

                        width: 33.333%;

                        font-size: remF(36);
                    }

                }

                .key__wrap {
                    margin-top: rem(3);
                    display: flex;
                    color: rgba(255, 255, 255, 0.5);
                    justify-content: space-between;
                    width: 100%;
                    // text-align: center;
                    .item__left__key {

                        width: 33.333%;

                        font-size: remF(24);
                    }

                }
            }


        }

        .item_border {
            border-top: 1px solid rgba(187, 187, 187, 100);
        }
    }
}
