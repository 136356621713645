/**
  盒子px转rem
 */
// @function rem($px) {
//     @return ($px/75) * 1rem;
// }
/**
    字号px转rem
   */
// @function remF($px) {
//     @return ($px/64) * 0.853333333rem;
// }
@function rem($px) {
    @return $px * 1px;
}

@function remF($px) {
    @return $px * 1px;
}
@function rem1($px) {
    @return ($px/37.5) * 1rem;
}
