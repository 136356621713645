@import '../../../../styles/index.scss';
.advantage {
    display: flex;
    justify-content: space-between;
    margin: rem(40) auto rem(120);
    box-sizing: border-box;
    width: $content-width;
    .advantageItem {
        width: rem(405);
        border-radius: rem(16);
        .topContent {
            border-top-left-radius: rem(16);
            border-top-right-radius: rem(16);
            background: linear-gradient(298deg, rgba(255, 212, 0, 0) 1%, #ffd400 100%);
            position: relative;
            width: rem(405);
            height: rem(212);
            .img {
                height: rem(48);
                width: rem(48);
                position: absolute;
                left: rem(32);
                bottom: rem(32);
            }
            .index {
                width: rem(48);
                height: rem(48);
                position: absolute;
                right: rem(32);
                bottom: rem(32);
            }
        }

        .bottomContent {
            border-bottom: 1px solid #eaecef;
            border-left: 1px solid #eaecef;
            border-right: 1px solid #eaecef;
            border-bottom-left-radius: rem(16);
            border-bottom-right-radius: rem(16);
            box-sizing: border-box;
            width: 100%;
            height: rem(220);
            padding: rem(22) rem(32) 0;
            .bottomtitle {
                //font-family: MiSans-Demibold;
                font-size: remF(24);
                font-weight: 600;
                line-height: rem(40);
                letter-spacing: 0px;
                color: #1e2329;

                span {
                    vertical-align: middle;
                }
                .line {
                    height: rem(24);
                    vertical-align: middle;
                    align-self: center;
                    width: rem(24);
                    margin-left: rem(10);
                    margin-right: rem(10);
                }
                ul {
                    //font-family: MiSans-Normal;
                    font-size: remF(18);
                    font-weight: normal;
                    line-height: rem(24);
                    letter-spacing: 0px;
                    color: #707a8a;
                    padding: 0;
                    padding-left: rem(20);
                    margin-top: rem(20);
                    font-weight: 300;
                    li {
                        margin-bottom: rem(12);
                    }
                }
            }
        }
        .bottomContent-en {
            height: rem(341);
            // font-family: Barlow-Light;
        }
    }
}
.advantage1024 {
    width: $content-width1024;
    margin: rem(30) auto rem(80);

    .advantageItem {
        width: rem(301);
        border-radius: rem(12);

        .topContent {
            border-top-left-radius: rem(12);
            border-top-right-radius: rem(12);
            background: linear-gradient(298deg, rgba(255, 212, 0, 0) 1%, #ffd400 100%);
            position: relative;
            width: rem(301);
            height: rem(157);
            .img {
                height: rem(32);
                width: rem(32);
                position: absolute;
                left: rem(24);
                bottom: rem(24);
            }
            .index {
                width: rem(32);
                height: rem(32);
                position: absolute;
                right: rem(24);
                bottom: rem(24);
            }
        }

        .bottomContent {
            height: rem(178);
            padding: rem(16) rem(16) 0;
            .bottomtitle {
                font-size: remF(20);
                font-weight: 600;
                line-height: rem(30);
                .line {
                    height: rem(16);
                    width: rem(16);
                    margin-left: rem(6);
                    margin-right: rem(6);
                }
                ul {
                    //font-family: MiSans-Normal;
                    font-size: remF(14);
                    line-height: rem(20);
                    li {
                        margin-bottom: rem(10);
                    }
                }
            }
        }
        .bottomContent-en {
            height: rem(241);
            .bottomtitle {
                ul {
                    margin-top: rem(9);
                    li {
                        margin-bottom: rem(8);
                    }
                }
            }
            // font-family: Barlow-Light;
        }
    }
}
