@import '../../../styles/index.scss';


.container {
    width: 100%;
    height: 100%;
    margin-bottom: rem(15);
    background-color: $bg-card;
    // padding: 0 0 rem(40) 0;

    .total_market_value {
        padding: rem(40) rem(40) rem(20) rem(30);
        .title {
            font-size: rem(24);
            color: $white-a;
        }
        .number {
            font-weight: 600;
            font-size: rem(48);
            margin-right: rem(10);
        }
        .rate {
            font-size: rem(26);
            position: relative;
            bottom: 4px;
        }
    }

    .margin_left_30 {
        margin-left: rem(30);
    }

    .margin_right_20 {
        margin-right: rem(20);
    }

    .color_white {
        font-size: rem(26);
        color: white;
    }

    .color_white_a {
        color: $white-a;
    }

    .title_bar {
        // margin: 0 rem(30) 0 rem(30);
        color: $white-a;
        font-size: rem(26);
    }

    .share_list {
        .share_item {
            border-bottom: 1px solid $bgc-n;
            margin: rem(10) 0;
            padding: rem(20) 0;
            color: $white-a;
        }
    }

    // 限制宽度
    .limit_width {
        width: rem(180);
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // 文字右对齐
    .right_align {
        text-align: right;
    }

    .value_increment {
        color: $red;
    }
    .value_descend {
        color: $green;
    }

    .fold_button {
        text-align: center;
        position: relative;
        color: $white-a;
        bottom: 2px;
        font-weight: 400;
        line-height: 30px;
        .upfold_down {
            cursor: pointer;
            border: 6px solid transparent;
            border-top: 6px $white-a solid;
            width: 0px;
            height: 0px;
            display: inline-block;
            position: relative;
            top: 4px;
            right: 3px;
            margin-right: 10px;
        }
    
        .fold_up {
            cursor: pointer;
            border: 6px solid transparent;
            border-bottom: 6px $white-a solid;
            width: 0px;
            height: 0px;
            display: inline-block;
            position: relative;
            bottom: 2px;
            right: 3px;
            margin-right: 10px;
        }    
    }
}
