@import '../../../../styles/modules/mobile-function.scss';

.mobile-header-container {
    background-color: #FFD400;
    font-size: rem(28);
    font-weight: 300;
    line-height: rem(36);
    border-radius: 5px 5px 0 0;
    color: #1E2329;
    text-align: left;
    vertical-align: middle;
    padding: rem(40) rem(35);
    width: 100%;
    position: relative;
    overflow: hidden;

    .mobile-header-img {
        position: absolute;
        width: rem(594);
        height: rem(283);
        object-fit: contain;
        left: rem(308);
        top: 0;
    }
}