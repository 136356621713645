@import '../../../../styles/index.scss';

.body {
    width: 100vw;
    padding-bottom: rem(120);
    background-color: #14161a;
    background-image: url('../../../../images/team-bg.png');
    background-size: rem(1356) rem(856);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-attachment: local;
    position: relative;
    -webkit-user-select: none; /* 禁止文本被选中 */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ul {
        list-style: none;
    }
    .title {
        width: $content-width;
        margin: rem(24) auto rem(84);
        //font-family: MiSans-Normal;
        font-size: remF(16);
        font-weight: 300;
        line-height: rem(26);
        color: rgba($color: #ffffff, $alpha: 0.6);
    }

    .scroll {
        padding: 0 $content-space-left;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: rem(32);
        .card {
            display: inline-block;
            width: rem(405);
            position: relative;
            flex-shrink: 0;
            height: rem(120);
            .img {
                width: rem(96);
                height: rem(96);
                border-radius: rem(96);
                position: absolute;
                top: 0;
                right: rem(24);
                z-index: 3;
            }
            .CustomHeaderContent {
                position: absolute;
                left: 0;
                top: rem(20);
                width: 100%;
                height: rem(100);
                overflow: hidden;
                border-top-left-radius: rem(16);
                border-top-right-radius: rem(16);
                background-image: url('../../../../images/team-top.png');
                background-size: 100% 100%;
                background-position: 0 0;
                background-repeat: no-repeat;
                background-attachment: local;
                background-color: #2c2e31;
                box-sizing: border-box;
                .top {
                    width: 100%;
                    height: rem(100);
                    padding: rem(16) rem(24);
                    .name {
                        //font-family: MiSans-Medium;
                        font-size: remF(20);
                        font-weight: 500;
                        line-height: rem(30);
                        height: rem(30);
                        letter-spacing: 0em;
                        color: #ffffff;
                    }
                    .bottomtitle {
                        //font-family: MiSans-Light;
                        font-size: remF(16);
                        font-weight: 300;
                        line-height: rem(24);
                        height: rem(24);
                        color: #ffffff;
                        margin-top: rem(6);
                    }
                }
            }
            .CustomLiContent {
                position: absolute;
                display: none;
                background: rgba(44, 46, 49, 1);
                backdrop-filter: blur(16px);
                top: rem(120);
                left: 0;
                padding: rem(24) 0;
                width: 100%;
                z-index: 10;
                border-bottom-left-radius: rem(16);
                border-bottom-right-radius: rem(16);
                .customli {
                    margin-bottom: rem(8);
                    font-size: remF(15);
                    font-weight: 300;
                    line-height: rem(24);
                    letter-spacing: 0px;
                    color: rgba($color: #ffffff, $alpha: 0.5);
                    padding: 0 rem(16);
                    box-sizing: border-box;
                    display: flex;
                    .circle {
                        vertical-align: middle;
                        width: rem(4);
                        height: rem(4);
                        border-radius: rem(4);
                        background-color: #969798;
                        flex-shrink: 1;
                        margin-top: rem(9);
                        margin-right: rem(5);
                    }
                    .text {
                        height: auto;
                        white-space: normal;
                    }
                }
            }
        }
    }
}
.body1024 {
    -webkit-user-select: none; /* 禁止文本被选中 */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .title {
        margin: rem(10) auto rem(41);
        font-size: remF(14);
        line-height: rem(24);
        width: $content-width1024;
    }
    .scroll {
        padding: 0 $content-space-left1024;
        gap: rem(24);
        .card {
            width: rem(298);
            height: rem(100);
            .img {
                width: rem(82);
                height: rem(82);
                border-radius: rem(82);
                right: rem(20);
            }
            .CustomHeaderContent {
                height: rem(80);
                .top {
                    padding: rem(16) rem(20);
                    // margin-bottom: rem(20);
                    .name {
                        font-size: remF(16);
                        line-height: rem(24);
                        height: rem(24);
                    }
                    .bottomtitle {
                        font-size: remF(14);
                        line-height: rem(20);
                        height: rem(20);
                        margin-top: rem(2);
                    }
                }
            }
            .CustomLiContent {
                top: rem(100);
                border-bottom-left-radius: rem(12);
                border-bottom-right-radius: rem(12);
                .customli {
                    margin-bottom: rem(4);
                    font-size: remF(14);
                    line-height: rem(24);
                    padding: 0 rem(12);
                }
            }
        }
    }
}
.body1024En {
    height: rem(720);
    background-size: rem(1140) rem(720);
    .title {
        margin: rem(10) auto rem(46);
    }
    .scroll {
        .card {
            height: rem(345);
            .CustomLiContent {
                height: rem(326);
                .customli {
                    // font-family: Barlow-Light;
                    letter-spacing: -0.2px;
                    .text {
                        width: rem(246);
                        line-height: rem(20);
                    }
                }
                .top {
                    margin-bottom: rem(16);
                }
            }
        }
    }
}
