@import '../../styles/index.scss';

@media (max-width: 576px) {
    .basic-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        // padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: rem(20);
        background-color: $bgc-n;

        .content-wrapper {
            overflow: hidden;
            height:100%;
            position: relative;
            min-height: 100%;
            padding-bottom: rem(130);
        }
    }
}

@media (min-width: 576px) {
    .basic-wrapper {
        position: relative;
        width: rem(750);
        height: 100%;
        margin: 0 auto;
        // padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: rem(20);
        background-color: $bgc-n;

        .content-wrapper {
            overflow: hidden;
            height:100%;
            position: relative;
            min-height: 100%;
            padding-bottom: rem(130);
        }
    }
}

// @media (min-width: 991px) {
//     .basic-wrapper {
//         @include wrapper-padding(300);
//     }
// }

// @media (min-width: 1199px) {
//     .basic-wrapper {
//         @include wrapper-padding(400,);
//     }
// }

// @media (min-width: 1599px) {
//     .basic-wrapper {
//         @include wrapper-padding(500);
//     }
// }
