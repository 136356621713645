$base: #f43c59;
$desc: #9A9A9B;
$confirm: #2A8FFE;
$line-color: #EFEFEF;
$bg-color: #FAFAF9;
$bg-card: #2C2C2C;
$red: #ff0000;
$green: #4CD964;
$white-a: rgba(255, 255, 255, 0.5);

$bgc-n: #1F1F1F;
$base-n: #FFC200;
