@import '../../styles/index.scss';

$look-orange: #fba100;
$look-yellow: #ffd400;
$margin-left: rem(28);

.download-container {
    width: 100%;
    height: 100vh;
    background: url('../../images/download-bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    // padding-bottom: 100px;
    .download-content-bg {
        margin: 0 auto;
        height: 100vh;
        width: $content-width;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        .download-title {
            margin-top: 200px;
            width: rem(1090);
        }
        .download-description {
            color: #424242;
            width: $content-width;
            font-size: 24px;
            font-weight: 300;
            margin-top: rem(32);
            line-height: rem(40);
            white-space: pre-wrap;
        }
        .row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: rem(180);
            .info-list {
                margin-top: rem(24);
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                > div {
                    font-size: remF(20);
                    font-weight: 300;
                    color: #474d57;
                    line-height: rem(32);
                }
            }
            .sub-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                .border {
                    border-radius: rem(8);
                    // padding: rem(0);
                    opacity: 1;
                    border: 1px solid #dddddd;
                    width: rem(152);
                    height: rem(152);
                    background-color: white;
                }
                .name {
                    font-size: remF(20);
                    font-family: Barlow-Regular, Barlow;
                    font-weight: 400;
                    color: #0d0d0e;
                    line-height: rem(28);
                    text-align: center;
                    margin-top: rem(8);
                }
                .right40 {
                    margin-right: rem(40);
                }
            }
        }
    }
}
.download-container-1024 {
    background: url('../../images/download-bg-mini.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    .download-content-bg {
        width: $content-width1024;

        .download-title {
            margin-top: 100px;
            width: rem(816);
        }
        .download-description {
            margin-top: rem(20);
            font-size: 20px;
            width: $content-width1024;
            line-height: rem(30);
        }
        .row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: rem(64);
            .info-list {
                margin-top: rem(32);
                row-gap: 6px;
                > div {
                    font-size: remF(16);
                }
            }
            .sub-row {
                .border {
                    border-radius: rem(8);
                    padding: rem(6);
                    opacity: 1;
                    border: 1px solid #dddddd;
                    width: rem(140);
                    height: rem(140);
                }
                .name {
                    font-size: remF(16);
                    font-weight: 400;
                    color: #0d0d0e;
                    line-height: rem(28);
                    text-align: center;
                    margin-top: rem(8);
                }
                .right40 {
                    margin-right: rem(32);
                }
            }
        }
    }
}
