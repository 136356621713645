@import '../../styles/index.scss';
.page {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#ffd700, #f2f2f2 rem(300));

    padding: rem(60) 0 rem(160) 0;
}
.mt_7 {
    margin-top: rem(7);
}

.mt_20 {
    margin-top: rem(20);
}

.ml_20 {
    margin-left: rem(30);
}

.content {
    padding: rem(40) 0 rem(485) 0;
    // padding: 0 0 rem(251) 0;
    padding-bottom: rem(485);
    background-color: $bgc-n;
    overflow: hidden;
    .card-item {
        padding-top: rem(40);
        overflow: hidden;
    }
}

.btn__wrap {
    width: rem(672) !important;
    display: inline-block;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: $bgc-n;
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
    border: 0px solid transparent;
    font-size: remF(32);
    z-index: 2;
    height: rem(155);
    .drawer {
        z-index: 1;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: rem(24);
        text-align: center;
        padding: 0;
        background-color: #353535;
        padding-bottom: rem(80);
        transition: 0.3s;
        height: rem(700);
        .drawer-title {
            background-color: #353535;
            font-size: 0.37333333rem;
            color: rgba(255, 255, 255, 0.8);
            padding: 0.42666667rem 0;
        }
        .pop__phone {
            font-size: remF(28);
            margin-top: rem(40);
            .phone-title {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 300;
                font-size: remB(28);
                text-align: center;
                color: rgba(255, 255, 255, 0.8);
                display: inline-block;
                margin-right: rem(12);
            }
        }
    }
    .show-drawer {
        transform: translateY(rem(-590));
    }
    .btn-container {
        z-index: 2;
        position: relative;
        background-color: #1f1f1f;
        border-top-left-radius: rem(24);
        border-top-right-radius: rem(24);
        height: 100%;
        border-top: 0px solid transparent;
        .btn {
            background-color: $base-n;
            height: rem(110);
            width: rem(672);
            margin: 0 0 rem(45);
            display: flex;
            justify-content: center;
            align-items: center;
            border-color: transparent;
            border-radius: rem(24);
        }
    }
}
.code__img {
    width: rem(300);
    height: rem(300);
}

@media (max-width: 576px) {
    .btn__wrap {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .btn__wrap {
        width: 540px;
    }
}
