@import '../../../../styles/index.scss';

.body {
    width: 100vw;
    height: rem(860);
    background-color: #fbfbfb;
    padding-top: rem(56);
    .container {
        width: $content-width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
            .icon {
                width: rem(80);
                height: rem(10);
            }
            .title {
                //font-family: MiSans-Demibold;
                font-size: remF(40);
                font-weight: 600;
                line-height: rem(56);
                color: #1e2329;
                margin-top: rem(10);
            }
            .subtitle {
                margin-top: rem(24);
                //font-family: MiSans-Normal;
                font-size: remF(16);
                font-weight: 300;
                line-height: rem(26);
                letter-spacing: 0px;
                color: #707a8a;
                width: rem(390);
            }
        }
        .right {
            width: rem(748);
            height: rem(748);
        }
    }
}

.body1024 {
    height: rem(576);
    padding-top: rem(48);
    .container {
        width: $content-width1024;
        .left {
            .icon {
                width: rem(72);
                height: rem(8);
            }
            .title {
                font-size: remF(32);
                line-height: rem(40);
                margin-top: rem(8);
            }
            .subtitle {
                margin-top: rem(10);
                //font-family: MiSans-Normal;
                font-size: remF(14);
                line-height: rem(24);
                width: rem(320);
            }
        }
        .right {
            width: rem(480);
            height: rem(480);
        }
    }
}
