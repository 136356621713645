@import '../../../../styles/index.scss';

.tech-contain {
    width: 100vw;
    height: rem(832);
    background-color: #FFFFFF;
    position: relative;

    .tech-subTitle {
        width: $content-width1024;
        margin: 0 auto;
        text-align: center;
        height: rem(60);
        margin: rem(64) auto rem(40) auto;
        font-size: remF(20);
        font-weight: 300;
        text-align: center;
        line-height: rem(30);
        letter-spacing: 0px;
        color: #1E2329;
    }

    .tech-content {
        width: 100%;
        height: rem(520);
        position: relative;

        .tech-content-bg {
            display: block;
            margin: 0 auto;
            text-align: center;
            width: rem(944);
            height: rem(380);
            object-fit: cover;
        }

        .tech-fullfill {
            width: 100%;
            height: 100%;
        }

        .tech-static-box {
            position: absolute;
            top: rem(144);
            height: rem(115);
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;

            .tech-static-item {
                width: rem(200);

                .tech-static-item-num {
                    font-size: 56px;
                    font-weight: bold;
                    text-align: center;
                    color: #1E2329;
                    margin-bottom: rem(10);
                }

                .tech-static-item-desc {
                    font-size: 16px;
                    font-weight: 300;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #1E2329;
                }
            }

            .tech-static-item:nth-child(2) {
                margin: 0 rem(172);
            }
        }

        .tech-selectBar {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: rem(64);
            width: rem(614);
            height: rem(56);
            border-radius: rem(28);
            background: #FFFFFF;
            display: flex;
            align-items: center;

            .tech-selectBar-btn {
                width: rem(148);
                height: rem(44);
                font-size: remF(16);
                line-height: rem(44);
                text-align: center;
                cursor: pointer;
                color: #707A8A;
            }

            .tech-selectBar-btn-active {
                border-radius: 22px;
                color: #1E2329;
                background: #FFC612;
            }
        }

    }
}

.tech-contain1440 {
    height: rem(1212);

    .tech-subTitle {
        width: $content-width1024;
        height: rem(80);
        margin: rem(80) auto rem(80) auto;
        font-size: remF(24);
        font-weight: 300;
        line-height: rem(40);
    }

    .tech-content {

        height: rem(740);


        .tech-content-bg {
            display: block;
            margin: 0 auto;
            text-align: center;
            width: rem(1280);
            height: rem(516);
            object-fit: cover;
        }


        .tech-fullfill {
            width: 100%;
            height: 100%;
        }

        .tech-static-box {
            top: rem(178);
            height: rem(160);

            .tech-static-item {
                width: rem(280);

                .tech-static-item-num {
                    font-size: remF(80);
                    font-weight: bold;
                    line-height: rem(96);
                    text-align: center;
                    letter-spacing: rem(2);
                }

                .tech-static-item-desc {
                    font-size: remF(22);
                    font-weight: 300;
                    line-height: rem(28);

                }
            }

            .tech-static-item:nth-child(2) {
                margin: 0 rem(176);
            }
        }

        .tech-selectBar {
            width: rem(840);
            height: rem(76);
            bottom: rem(80);

            .tech-selectBar-btn {
                width: rem(200);
                height: rem(60);
                font-size: remF(20);
                line-height: rem(60);
                font-weight: 500;
            }

        }

    }
}