@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.body {
    width: 100vw;
    background-color: #14161a;
    position: relative;

    // background-image: url('../../../../images/team-bg.png');
    // background-size: rem(1356) rem(856);
    // background-position: 0 0;
    // background-repeat: no-repeat;
    // background-attachment: local;
    ul {
        list-style: none;
    }
    .title {
        margin: rem(24) rem(32) rem(84);
        //font-family: MiSans-Normal;
        font-size: remF(26);
        font-weight: 300;
        line-height: rem(40);
        color: rgba($color: #ffffff, $alpha: 0.6);
    }
    .slider {
        height: rem(10);
        width: rem(240);
        position: absolute;
        border-radius: rem(10);
        bottom: rem(80);
        left: calc((100% - 240rem / 75) / 2);
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        .sliderContent {
            width: rem(70);
            height: rem(10);
            border-radius: rem(10);
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ffd400;
        }
    }
    .scroll {
        overflow-x: auto;
        padding: 0 rem(32) rem(120);
        // width: auto;
        white-space: nowrap;
        display: flex;
        align-items: center;
        .card {
            display: inline-flex;
            flex-direction: column;
            width: rem(580);
            flex-shrink: 0;
            margin-right: rem(32);

            .cardItem:first-child {
                margin-bottom: rem(30);
            }

            .cardItem {
                width: 100%;
                height: rem(482);
                position: relative;
                .img {
                    width: rem(144);
                    height: rem(144);
                    border-radius: rem(96);
                    position: absolute;
                    top: 0;
                    right: rem(30);
                    z-index: 3;
                }
                .CustomLiContent {
                    position: absolute;
                    left: 0;
                    top: rem(32);
                    width: rem(580);
                    height: rem(450);
                    overflow: hidden;
                    border-radius: rem(16);
                    background-image: url('../../../../images/team-top.png');
                    background-size: 100%;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-attachment: local;
                    background-color: #2c2e31;
                    box-sizing: border-box;
                    .customli {
                        background-color: #2c2e31;
                        z-index: 2;
                        //font-family: MiSans-Light;
                        font-size: remF(24);
                        font-weight: 300;
                        line-height: rem(32);
                        letter-spacing: 0px;
                        color: rgba($color: #ffffff, $alpha: 0.5);
                        padding: rem(16) rem(16) 0;
                        box-sizing: border-box;
                        width: rem(580);
                        display: flex;
                        .circle {
                            vertical-align: middle;
                            width: rem(10);
                            height: rem(10);
                            border-radius: rem(10);
                            background-color: #969798;
                            flex-shrink: 1;
                            margin-top: rem(11);
                            margin-right: rem(16);
                        }
                        .text {
                            width: rem(489);
                            height: auto;
                            white-space: normal;
                        }
                    }
                    .top {
                        width: 100%;
                        height: rem(144);
                        border-radius: rem(16);
                        padding: rem(28) rem(32);
                        .name {
                            //font-family: MiSans-Medium;
                            font-size: remF(34);
                            font-weight: 500;
                            line-height: rem(40);
                            height: rem(40);
                            letter-spacing: 0em;
                            color: #ffffff;
                        }
                        .bottomtitle {
                            //font-family: MiSans-Light;
                            font-size: remF(26);
                            font-weight: 300;
                            line-height: rem(40);
                            height: rem(40);
                            color: #ffffff;
                            margin-top: rem(8);
                        }
                    }
                }
            }
        }
    }

    // 隐藏横向滚动条滚动条
    .hide-scrollbar {
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        // overflow-y: auto;
    }

    .hide-scrollbar::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        color: transparent;
        display: none;
    }
}
.bodyEn {
    width: 100vw;
    background-color: #14161a;
    // background-image: url('../../../../images/team-bg.png');
    // background-size: rem(1356) rem(856);
    // background-position: 0 0;
    // background-repeat: no-repeat;
    // background-attachment: local;
    ul {
        list-style: none;
    }
    .title {
        margin: rem(20) rem(32) rem(52);
        //font-family: MiSans-Normal;
        font-size: remF(26);
        font-weight: 300;
        line-height: rem(40);
        color: rgba($color: #ffffff, $alpha: 0.6);
    }

    .scroll {
        overflow-x: auto;
        padding: 0 rem(32) rem(120);
        // width: auto;
        white-space: nowrap;
        display: flex;
        align-items: center;
        .card {
            display: inline-flex;
            flex-direction: column;
            width: rem(580);
            flex-shrink: 0;
            margin-right: rem(32);

            .cardItem:first-child {
                margin-bottom: rem(30);
            }

            .cardItem {
                width: 100%;
                height: rem(613);
                position: relative;
                .img {
                    width: rem(144);
                    height: rem(144);
                    border-radius: rem(96);
                    position: absolute;
                    top: 0;
                    right: rem(30);
                    z-index: 3;
                }
                .CustomLiContent {
                    position: absolute;
                    left: 0;
                    top: rem(32);
                    width: rem(580);
                    height: rem(582);
                    overflow: hidden;
                    border-radius: rem(16);
                    background-image: url('../../../../images/team-top.png');
                    background-size: 100%;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-attachment: local;
                    background-color: #2c2e31;
                    box-sizing: border-box;
                    .customli {
                        background-color: #2c2e31;
                        z-index: 2;
                        //font-family: MiSans-Light;
                        font-size: remF(28);
                        font-weight: 300;
                        line-height: rem(34);
                        letter-spacing: 0px;
                        color: rgba($color: #ffffff, $alpha: 0.5);
                        padding: rem(16) rem(32) 0;
                        box-sizing: border-box;
                        width: rem(580);
                        display: flex;
                        .circle {
                            vertical-align: middle;
                            width: rem(10);
                            height: rem(10);
                            border-radius: rem(10);
                            background-color: #969798;
                            flex-shrink: 1;
                            margin-top: rem(11);
                            margin-right: rem(16);
                        }
                        .text {
                            width: rem(489);
                            height: auto;
                            white-space: normal;
                        }
                    }
                    .top {
                        width: 100%;
                        height: rem(144);
                        border-radius: rem(16);
                        padding: rem(28) rem(32);
                        .name {
                            //font-family: MiSans-Medium;
                            font-size: remF(34);
                            font-weight: 500;
                            line-height: rem(40);
                            height: rem(40);
                            letter-spacing: 0em;
                            color: #ffffff;
                        }
                        .bottomtitle {
                            //font-family: MiSans-Light;
                            font-size: remF(26);
                            font-weight: 300;
                            line-height: rem(40);
                            height: rem(40);
                            color: #ffffff;
                            margin-top: rem(8);
                        }
                    }
                }
            }
        }
    }

    // 隐藏横向滚动条滚动条
    .hide-scrollbar {
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        // overflow-y: auto;
    }

    .hide-scrollbar::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        color: transparent;
        display: none;
    }
}
