@import 'modules/variables';
@import 'modules/mixin';
@import 'modules/function';
@import 'modules/global';
// @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
// $content-space-left: calc((100vw - 1440rem / 75) / 2);
$content-width: rem(1280);
$content-width1024: rem(944);
$content-space-left1024: calc((100vw - 944px) / 2);
$content-space-left: calc((100vw - 1280px) / 2);
