@import '../../../../styles/index.scss';
.content {
    width: 100vw;
    // height: rem(800);
    padding: rem(120) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
        flex: 1;
        height: rem(450);
        width: $content-width;
        display: flex;
        cursor: pointer;
        .img {
            height: rem(450);
            object-fit: cover;
            width: 50%;
        }
        .textContent {
            height: rem(450);
            display: flex;
            flex-direction: column;
            background-color: #f8f8f8;
            width: 50%;
            box-sizing: border-box;
            justify-content: center;
            padding-left: rem(40);
            padding-right: rem(40);
            .topicIcon {
                height: rem(33);
                width: rem(200);
                position: relative;
                .topicIconTitle {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    //font-family: MiSans-Semibold;
                    font-size: remF(24);
                    font-weight: 600;
                    line-height: rem(30);
                    letter-spacing: 0em;
                    color: #171819;
                    z-index: 20;
                }
                .topicIconIcon {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: rem(16);
                    width: rem(90);
                    z-index: 10;
                    // margin-top: rem(70);
                }
            }
            .title {
                margin-top: rem(35);
                //font-family: MiSans-Medium;
                font-size: remF(24);
                font-weight: 500;
                line-height: rem(36);
                letter-spacing: rem(0.86);
                color: #171819;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .body {
                margin-top: rem(20);
                //font-family: MiSans-Normal;
                color: #707a8a;
                font-size: remF(16);
                font-weight: 300;
                line-height: rem(25);
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .more {
                //font-family: MiSans-Regular;
                font-size: remF(16);
                font-weight: normal;
                line-height: rem(20);
                letter-spacing: 0em;
                color: #171819;
                margin-top: rem(32);
                span {
                    vertical-align: middle;
                }
                .imgmore {
                    margin-left: rem(8);
                    width: rem(28);
                    height: rem(14);
                    vertical-align: middle;
                }
            }
        }
        .textContentEn {
            .topicIcon {
                height: rem(33);
                width: rem(200);
                position: relative;
                .topicIconTitle {
                    font-size: remF(28);
                }
            }
            .title {
                font-size: remF(26);
                line-height: rem(40);
            }
            .body {
                font-weight: 400;
            }
            .more {
                //font-family: MiSans-Regular;
                font-size: remF(16);
                font-weight: 400;
            }
        }
    }
    :nth-child(2) {
        flex-direction: row-reverse;
    }
}
.content1024 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
        flex: 1;
        height: 100%;
        height: rem(320);
        display: flex;
        width: $content-width1024;

        cursor: pointer;
        .img {
            height: rem(320);
            object-fit: cover;
            width: 50%;
        }
        .textContent {
            height: rem(320);
            display: flex;
            flex-direction: column;
            width: 50%;
            box-sizing: border-box;
            justify-content: center;
            padding-left: rem(28);
            padding-right: rem(28);
            .topicIcon {
                height: rem(30);
                width: rem(200);
                position: relative;
                .topicIconTitle {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    //font-family: MiSans-Semibold;
                    font-size: remF(20);
                    font-weight: 600;
                    line-height: rem(30);
                    letter-spacing: 0em;
                    color: #171819;
                    z-index: 20;
                }
                .topicIconIcon {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: rem(10);
                    width: rem(80);
                    z-index: 10;
                    // margin-top: rem(70);
                }
            }
            .title {
                margin-top: rem(20);
                //font-family: MiSans-Medium;
                font-size: remF(18);
                font-weight: 500;
                line-height: rem(28);
                letter-spacing: rem(0.86);
                color: #171819;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .body {
                margin-top: rem(12);
                //font-family: MiSans-Normal;
                color: #707a8a;
                font-size: remF(14);
                font-weight: 300;
                line-height: rem(22);
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .more {
                //font-family: MiSans-Regular;
                font-size: remF(14);
                font-weight: normal;
                line-height: rem(20);
                letter-spacing: 0em;
                color: #171819;
                margin-top: rem(16);
                span {
                    vertical-align: middle;
                }
                .imgmore {
                    margin-left: rem(8);
                    width: rem(28);
                    height: rem(14);
                    vertical-align: middle;
                }
            }
        }
        .textContentEn {
            .topicIcon {
                width: rem(200);
                position: relative;
                .topicIconTitle {
                    font-size: remF(24);
                }
            }
            .title {
                font-size: remF(18);
                line-height: rem(28);
            }
            .body {
                font-weight: 400;
            }
            .more {
                //font-family: MiSans-Regular;
                font-size: remF(16);
                font-weight: 400;
            }
        }
    }
    :nth-child(2) {
        flex-direction: row-reverse;
    }
}
