@import '../../styles/index.scss';

.content {
    .tel-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        background-color: #2C2C2C;
        margin: rem(64) rem(48) 0;
        height: rem(100);
        border-radius: rem(24);

        .tel {
            font-size: remF(32);

        }

        .num {
            font-size: remF(36);
        }
    }
}
