
@import "../../styles/index.scss";

.header {
    display: flex;
    height: rem(100);
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1000;

    .title {
        font-size: remF(36);
        color: #fff;
    }

    .back-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: rem(120);
        height: 100%;
    }

    .back-icon {
        // top: rem(50);
        // left: rem(50);
    // transform: translate(-50%,-50%);
        @include flex-center();
        color: #fff;
        width: rem(32);
        height: rem(28);
    }

    .home {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: rem(20);
        width: rem(80);
        height: 100%;

        .img {
            @include flex-center();
            width: rem(35);
            height: rem(35);
        }
    }
}
