@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.body {
    width: 100vw;
    background-color: #f7f7f8;
    padding-bottom: rem(80);
    .subtitle {
        margin-top: rem(20);
        //font-family: MiSans-Normal;
        font-size: remF(26);
        font-weight: 400;
        line-height: rem(40);
        letter-spacing: 0px;
        color: #707a8a;
        padding: 0 rem(32) rem(40);
        width: 100%;
    }
    .right {
        width: 100%;
        height: calc(100% / 75 * 74);
    }
}
