@import '../../../../styles/index.scss';
.content {
    width: 100vw;
    background-color: #fbfbfb;

    .stickTop {
        height: rem(400);
        width: 100%;

        .downIcon {
            width: 100%;
            height: rem(128);
            position: absolute;
            left: 0;
            bottom: -10px;
        }
        .stickbg {
            background-color: #fbfbfb;
            width: 100%;
            height: rem(272);
            padding-top: rem(120);
            box-sizing: border-box;
            .containerImg {
                width: $content-width;
                margin: 0 auto;
                .lkAdvantageLine {
                    width: rem(88);
                    height: rem(10);
                }
            }

            .title {
                //font-family: MiSans-Demibold;
                font-size: remF(40);
                font-weight: 600;
                line-height: rem(56);
                letter-spacing: 0px;
                width: $content-width;
                margin: 0 auto;
                color: #1e2329;
            }
            .topTitle {
                width: $content-width;
                margin: rem(24) auto 0;
                //font-family: MiSans-Normal;
                font-weight: 300;
                font-size: remF(16);
                line-height: rem(26);
                letter-spacing: 0px;
                color: #707a8a;
                white-space: pre-wrap;
            }
        }

        .topIcon {
            width: 100%;
            height: rem(128);
        }
    }

    .container {
        width: $content-width;
        // position: absolute;
        margin: 0 auto;
        // height: rem(1320);
        padding-bottom: rem(320);
        // top: 0;
        // z-index: 4;
        .title {
            //font-family: MiSans-Demibold;
            font-size: remF(40);
            font-weight: 600;
            line-height: rem(60);
            color: #1e2329;
            width: 100%;
            text-align: center;
        }
        .desc {
            margin-top: rem(8);
            width: 100%;
            text-align: center;
            //font-family: MiSans-Light;
            font-size: remF(32);
            font-weight: 300;
            line-height: rem(48);
            letter-spacing: 0px;
            color: #1e2329;
        }
        .cardContent {
            height: rem(480);
            width: $content-width;
            margin: 0 auto;
        }
    }
    .container1 {
        .cardContent {
            margin-top: rem(48);
            display: flex;
            justify-content: space-between;
            .cardItem {
                width: rem(405);
                height: rem(480);
                background-position: 0 0;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-attachment: local;
                background-color: white;
                padding: rem(64) rem(40);
                border-radius: rem(16);
                display: flex;
                flex-direction: column;
                .cardIcon {
                    width: rem(56);
                    height: rem(56);
                    margin-left: rem(24);
                }
                .cardTitle {
                    // font-size: remF(24);
                    // font-weight: 600;
                    // line-height: rem(32);
                    font-size: remF(30);
                    font-weight: normal;
                    line-height: 32px;
                    letter-spacing: 1px;
                    color: #000000;
                    flex-grow: 1;
                }
                .descContent {
                    //font-family: MiSans-Light;
                    display: flex;
                    flex-wrap: wrap-reverse;
                    row-gap: rem(16);
                    column-gap: rem(16);
                    font-size: remF(18);
                    margin-top: rem(24);
                    font-weight: 300;
                    line-height: rem(32);
                    letter-spacing: 0px;
                    color: #707a8a;
                    white-space: break-spaces;
                    .descItem {
                        width: calc(33% - 10px);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .descIcon {
                            width: rem(48);
                            margin-bottom: rem(16);
                        }
                        font-size: remF(20);
                        font-weight: normal;
                        line-height: 32px;
                    }
                }
            }
            //选中第一个carditem
            .cardItem:nth-child(1) {
                background-image: url('../../../../images/support_bg1.png');
            }
            .cardItem:nth-child(2) {
                background-image: url('../../../../images/support_bg2.png');
            }
            .cardItem:nth-child(3) {
                background-image: url('../../../../images/support_bg3.png');
            }
            .cardItemEn {
                .cardTitle {
                    margin-top: rem(20);
                    // font-family: Barlow-SemiBold;
                }
                .cardDesc {
                    margin-top: rem(20);
                    line-height: rem(24);
                    font-size: remF(17);
                    // font-family: Barlow-Light;
                    ul li {
                        margin-bottom: rem(6);
                        margin-left: rem(16);
                    }
                }
                padding: rem(40) rem(32) 0;
            }
        }
    }
    .container2 {
        top: rem(1320);
        .cardContent {
            margin-top: rem(48);
            display: flex;
            justify-content: space-between;
            .cardLeft {
                width: rem(360);
                height: rem(480);
                background-image: url('../../../../images/service-card-bg.png');
                background-position: 0 0;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-attachment: local;
                background-color: #ffd400;
                padding: rem(48) rem(40);
                border-radius: rem(16);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .cardLeftTop {
                    //font-family: MiSans-Demibold;
                    font-size: remF(32);
                    font-weight: 600;
                    line-height: rem(40);
                    letter-spacing: 1px;
                    color: #000000;
                }
                .cardLeftBottom {
                    .cardLeftBottomIcon {
                        width: rem(136);
                        height: rem(136);
                    }
                    .cardLeftBottomName {
                        //font-family: MiSans-Demibold;
                        font-size: remF(32);
                        font-weight: 600;
                        line-height: rem(36);
                        letter-spacing: 0em;
                        color: #000000;
                        margin-top: rem(36);
                    }
                    .cardLeftBottomTitle {
                        //font-family: MiSans-Normal;
                        margin-top: rem(8);
                        font-size: remF(20);
                        font-weight: normal;
                        line-height: rem(30);
                        letter-spacing: 0em;
                        color: #000000;
                    }
                }
            }
            .cardRight {
                width: rem(850);
                height: rem(480);
                .cardRightTop {
                    width: 100%;
                    height: rem(160);
                    border-radius: rem(16);
                    background-color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 rem(50);
                    .cardRightTopLeft {
                        display: flex;
                        align-items: center;
                        //font-family: MiSans-Demibold;
                        font-size: remF(26);
                        font-weight: 600;
                        line-height: rem(40);
                        letter-spacing: 0px;
                        color: #1e2329;
                        .cardRightTopLeftIcon {
                            width: rem(64);
                            margin-right: rem(24);
                            height: rem(64);
                        }
                    }
                    .line {
                        width: 1px;
                        height: rem(120);
                        background-color: #f0f0f0;
                    }
                }
                .cardRightBottom {
                    width: 100%;
                    margin-top: rem(32);
                    height: rem(288);
                    background-color: white;
                    border-radius: rem(16);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-content: space-between;
                    padding: rem(32) rem(16);
                    .cardRightBottomItem {
                        width: calc(50% - 10px);

                        height: auto;
                        .cardRightBottomItemTop {
                            //font-family: MiSans-Medium;
                            font-size: remF(20);
                            font-weight: 500;
                            line-height: rem(30);
                            letter-spacing: 0px;
                            .block {
                                width: rem(8);
                                height: rem(8);
                                display: inline-block;
                                background-color: #ffd400;
                                vertical-align: middle;
                                margin-right: rem(10);
                                margin-bottom: rem(2);
                            }
                        }
                        .cardRightBottomItemDesc {
                            margin-top: rem(10);
                            //font-family: MiSans-Normal;

                            font-size: remF(15);
                            font-weight: 300;
                            line-height: rem(24);
                            letter-spacing: 0px;
                            color: #707a8a;
                        }
                    }
                }
            }
        }
    }
    .containerEn2 {
        .cardContent {
            .cardLeft {
                .cardLeftTop {
                    //font-family: MiSans-Demibold;
                    font-size: remF(32);
                }
                .cardLeftBottom {
                    .cardLeftBottomIcon {
                        width: rem(128);
                        height: rem(128);
                    }
                    .cardLeftBottomName {
                        //font-family: MiSans-Demibold;
                        font-size: remF(28);
                        line-height: rem(40);
                    }
                    .cardLeftBottomTitle {
                        //font-family: MiSans-Normal;
                        margin-top: rem(4);
                    }
                }
            }
            .cardRight {
                width: rem(889);
                .cardRightTop {
                    height: rem(100);
                    padding: 0 rem(32);
                    .cardRightTopLeft {
                        display: flex;
                        align-items: center;
                        //font-family: MiSans-Demibold;
                        font-size: remF(20);
                        font-weight: 400;
                        line-height: rem(24);
                        letter-spacing: 0px;
                        color: #1e2329;
                        .cardRightTopLeftIcon {
                            width: rem(56);
                            margin-right: rem(16);
                            height: rem(56);
                        }
                    }
                    .line {
                        width: 0px;
                        //     height: rem(120);
                        //     background-color: #f0f0f0;
                    }
                }
                .cardRightBottom {
                    height: rem(348);
                    padding: rem(24) rem(28);

                    .cardRightBottomItem {
                        width: calc(50% - 10px);
                        height: auto;
                        .cardRightBottomItemTop {
                            //font-family: MiSans-Medium;
                            font-size: remF(18);
                            font-weight: 600;
                            line-height: rem(30);
                            color: #1a1a1a;
                            .block {
                                width: rem(8);
                                height: rem(8);
                                display: inline-block;
                                background-color: #ffd400;
                                margin-right: rem(6);
                                margin-bottom: rem(2);
                            }
                        }
                        .cardRightBottomItemDesc {
                            margin-top: rem(6);
                            // font-family: Source Han Sans CN;

                            font-size: remF(15);
                            font-weight: 300;
                            line-height: rem(22);
                            color: #707a8a;
                        }
                    }
                }
            }
        }
    }
    .container3 {
        padding-bottom: rem(200);
        .cardContent {
            height: rem(508);
            width: rem(1280);
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .button {
            width: rem(476);
            height: rem(66);
            background-color: #edeff4;
            border-radius: rem(66);
            margin: rem(40) auto;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            padding: rem(6) rem(8) rem(8);
            .buttonItem {
                line-height: rem(52);
                height: rem(52);
                width: rem(148);
                //font-family: MiSans-Medium;
                font-size: remF(20);
                // font-weight: 500;
                letter-spacing: 0px;

                color: #707a8a;

                text-align: center;
                border-radius: rem(52);
            }
            .selectButton {
                background-color: white;
                font-weight: normal;
                color: #1e2329;
            }
        }
    }
    .containerEn3 {
        .button {
            width: rem(572);
            height: rem(68);
            .buttonItem {
                width: rem(180);
            }
        }
    }
    .noInbody {
        position: absolute;
        top: rem(0);
    }
    .noneDisplay {
        display: none;
    }
}
