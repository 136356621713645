@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';

.aum-contain-mobile {
    width: 100vw;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    height: rem(744);
    position: relative;

    .aum-content {
        padding: 0 rem(32);
        width: 100vw;
        margin: rem(32) auto 0 auto;

        .aum-contain-mobile-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: url('../../../../images/aum-bg-mobile.png') no-repeat;
            background-size: cover;
            padding-bottom: rem(20);

            .aum-content-des2 {
                margin-top: rem(80);
                font-size: remF(120);
                line-height: rem(144);
                font-weight: bold;
                color: #121518;
            }

            .aum-content-des3 {
                font-size: remF(32);
                font-weight: normal;
                margin: rem(20) 0;
                font-weight: normal;
            }

            .aum-content-des4 {
                font-size: remF(24);
                font-weight: 300;
                line-height: 32px;
                color: #4D5159;
            }
        }
    }
}