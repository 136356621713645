@import '../../styles/index.scss';

.login-wrap {
    margin: 0 rem(48);
    background-color: $bgc-n;

    .tips {
        margin-top: rem(64);
        font-size: remF(64);
        color: #fff;
    }

    .login-box {
        padding: 0 rem(24);
        margin-top: rem(80);
        font-size: remF(32);
        background-color: #2c2c2c;
        border-radius: rem(24);

        .line {
            display: flex;
            height: rem(103);

            .left {
                border-right: 2px solid rgba(255, 255, 255, 0.1);
                border-bottom: 2px solid rgba(255, 255, 255, 0.1);
                color: rgba(255, 255, 255, 0.8);

                input {
                    font-size: rem(32);
                    color: rgba(255, 255, 255, 0.8);

                    padding: 0;
                }
                flex: 1;
                display: flex;
                align-items: center;
            }

            .right {
                color: rgba(255, 255, 255, 0.5);
                input {
                    font-size: rem(32);
                    color: rgba(255, 255, 255, 0.5);
                }
                flex: 3;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-bottom: 2px solid rgba(255, 255, 255, 0.1);

                .code {
                    position: absolute;
                    height: 100%;
                    top: 0;
                    right: 0;
                    color: $base-n;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .next-step-btn {
            margin-top: rem(40);
            background-color: $base-n;
            color: rgba(0, 0, 0, 1);
            width: rem(654);
            height: rem(110);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: remF(32);
            border-color: transparent;
            border-radius: rem(24);
        }

        .next-step-btn-disable {
            background-color: #676767;
        }
    }
}
