@import '../../styles/index.scss';
@import '../../styles/modules/mobile-function.scss';

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none !important;
}

.ant-drawer-body {
    padding: 0 rem(48) rem(0) !important;
}

.drawer-bottom-area {
    height: rem(200);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: rem(200);
    font-weight: 400;
    font-size: remF(34);

    .drawer-bottom-area-chinese {
        padding: 0 rem(40);
        height: 100%;
    }

    .drawer-bottom-area-line {
        width: 1px;
        height: rem(40);
        background: #d8d8d8;
    }

    .drawer-bottom-area-english {
        padding: 0 rem(40);
        height: 100%;
    }
}

html {
    scroll-padding-top: rem(120);
}

.H1 {
    text-align: center;
    font-size: remF(64);
    font-weight: 600;
    color: #0d0e0f;

    span {
        color: #fba100;
    }

    + p {
        font-size: remF(30);
        color: #474d57;
        margin: rem(25) rem(55) rem(40);
    }
}

.menu-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: rem(30) 0;

    span {
        color: #1e2329;
        font-size: remF(34);
        line-height: remF(44);
        flex-grow: 1;
    }

    span.highlighted {
        color: #eda400;
    }

    .divider {
        position: absolute;
        background-color: #f5f5f8;
        bottom: 0;
        width: 100%;
        height: 1px;
    }
}

.section-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(24) rem(40);
    background-color: transparent;

    .logo {
        height: rem(40);
    }

    .menu {
        width: rem(64);
    }
}

.section-header-white {
    background-color: white;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
}

.content {
    height: 100vh;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.drawer {
    margin-top: rem(120);
}

.top-anchor {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
}

// .nav-anchor {
//     padding-top: rem(120);
//     height: 1px;
//     width: 1px;
// }
.poster {
    height: rem(640);
    background-image: url('./mobile_images/bg_poster.png');
    background-size: 100%;
    background-position: bottom;
    padding: rem(240) rem(80) rem(150);

    img {
        max-width: 100%;
    }
    div {
        margin-top: rem(24);
        color: #000100;
        font-size: remF(24);
        font-weight: normal;
        line-height: rem(40);
    }
    .tradingview-widget-copyright {
        display: none;
    }
}

.posterEn {
    background-image: url('./mobile_images/home_header.png');
}
