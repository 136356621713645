@import '../../../../styles/index.scss';

.aum-contain {
    width: 100vw;
    background-color: #fbfbfb;
    height: rem(630);
    position: relative;

    .aum-content {
        position: absolute;
        top: rem(73);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: $content-width1024;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url('../../../../images/aum-bg.png') no-repeat;
        background-size: cover;

        .aum-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .aum-content-des1 {
            font-size: remF(20);
            font-weight: 300;
            color: #4D5159;
            margin-top: rem(40);
        }

        .aum-content-des2 {
            font-size: remF(64);
            font-weight: bold;
            margin-top: rem(40);
            line-height: rem(72);
            color: #121518;
        }

        .aum-content-des3 {
            font-size: remF(20);
            font-weight: normal;
            text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
        }

        .aum-content-des4 {
            font-size: remF(14);
            font-weight: 300;
            color: #4D5159;
        }
    }
}

.aum-contain1440 {
    width: 100vw;
    height: rem(960);
    background: url('../../../../images/aum-bg1440.png') no-repeat;
    background-size: cover;
    background-position-x: 50%;

    .aum-content {
        background: none;

        .aum-bg {}

        .aum-content-des1 {
            font-size: remF(24);
        }

        .aum-content-des2 {
            font-size: remF(80);
            margin-top: rem(40);
            margin-bottom: rem(32);
        }

        .aum-content-des3 {
            font-size: remF(24);
            text-shadow: none;
        }

        .aum-content-des4 {
            font-size: remF(18);
        }
    }
}