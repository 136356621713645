@import '../../../../styles/index.scss';

.wrap {
    margin: 0 rem(48) 0;
    border-radius: rem(10);

    .login_wrapper {
        display: flex;
        justify-content: center;
        .float_div {
            color: white;
            position: absolute;
            text-align: center;
            z-index: 100;
            .dropdown {
                color: $base-n;
                padding: rem(10) 0;
                .img {
                    width: rem(35);
                    height: rem(18);
                }
            }
            .complete_info {
                color: $base-n;
                font-size: rem(30);
                font-weight: 500;
            }
            .legal_tips {
                color: $white-a;
                font-size: rem(20);
            }
        }
    }

    .title {
        font-size: remF(28);
        margin-bottom: rem(24);
        color: #fff9ed;
    }

    .list {
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: rem(96);
            padding: rem(24) 0;
            border-bottom: 1px solid #343434;
            overflow: hidden;

            .left {
                flex: 1;
                font-size: rem(26);
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 300;
                line-height: rem(40);
                height: rem(40);
                color: rgba(255, 255, 255, 0.8);
            }

            .right {
                flex: 1;
                font-weight: 600;
                font-size: rem(26);
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                text-align: right;
                color: #ffffff;
            }
        }
    }
}
