@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';

.container {
    background-color: white;
    width: 100vw;
    padding-bottom: rem(120);

    .content {
        .tip {
            margin: rem(20) rem(32) 0;
            //font-family: MiSans-Normal;
            font-size: remF(26);
            font-weight: normal;
            line-height: rem(40);
            white-space: break-spaces;
            letter-spacing: 0px;
            color: #707a8a;
        }

        .tip2 {
            margin: rem(20) rem(32) 0;
            //font-family: MiSans-Normal;
            font-size: remF(26);
            font-weight: normal;
            line-height: rem(40);
            white-space: break-spaces;
            letter-spacing: 0px;
            color: #707a8a;
        }

        .mapimg {
            margin-top: rem(48);
            width: 100%;
            object-fit: cover;
        }

        // .tip + :nth-child(2) {
        //     width: initial !important;
        //     height: initial !important;
        //     margin-top: rem(80) !important;
        // }
        .top {
            //font-family: MiSans-Demibold;
            font-size: remF(34);
            font-weight: 600;
            line-height: rem(40);
            letter-spacing: 0px;
            margin-top: rem(80);
            color: #1e2329;
            width: 100%;
            text-align: center;
        }

        .bottom {
            width: 100%;
            text-align: center;
            margin-top: rem(20);
            //font-family: MiSans-ExtraLight;
            font-size: remF(26);
            font-weight: 200;
            line-height: rem(40);
            letter-spacing: rem(10);
            color: #424242;
        }

        .imgtop {
            width: 100%;
            text-align: center;
            //font-family: MiSans-Light;
            font-size: remF(26);
            font-weight: 300;
            line-height: rem(40);
            letter-spacing: rem(3.6);
            color: #1e2329;
        }

        .imgbottom {
            width: 100%;
            padding: rem(0) rem(32);
            text-align: center;
            margin-top: rem(16);
            //font-family: MiSans-ExtraLight;
            font-size: remF(24);
            font-weight: 200;
            line-height: rem(34);
            letter-spacing: rem(0.4);
            color: #424242;
        }

        .slogan {
            display: flex;
            padding: 0 rem(40);
            margin-top: rem(40);

            >img {
                width: rem(88);
                height: rem(88);
                margin-right: rem(32);
            }

            .slogan_title {
                font-size: rem(36);
                font-weight: 600;
                line-height: rem(44);
                letter-spacing: 1px;
                color: #000000;
            }

            .slogan_desc {
                font-size: rem(28);
                font-weight: 300;
                line-height: rem(40);
                letter-spacing: 0px;

                color: #707A8A;
            }
        }
    }
}

.containerEn {
    padding-bottom: rem(80);

    .content {
        .bottom {
            letter-spacing: 0;
            text-align: center;
            margin: rem(20) rem(30) rem(32);
            width: auto;
        }
    }
}