@import '../../../../styles/modules/mobile-function.scss';

.lkAdvantageLine {
    width: rem(88);
    height: rem(10);
    margin-top: rem(80);
}

.title {
    //font-family: MiSans-Demibold;
    padding-left: rem(32);
    padding-top: rem(10);
    font-size: remF(40);
    font-weight: 600;
    color: #1e2329;
    line-height: rem(56);
}

.titleEn {
    padding-left: rem(40);
    padding-top: rem(10);
    font-size: remF(40);
    font-weight: 250;
    color: #1e2329;
    line-height: rem(48);
}