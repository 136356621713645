@import '../../styles/index.scss';

.tabs-container {
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1000;
    width: inherit;
    height: rem(130);
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: content-box;
    background-color: $bgc-n;
    padding-bottom: rem(20);
    // padding-bottom: env(safe-area-inset-bottom);
    box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.1);

    .tab-item {
        flex: 1;
        &:first-child {
            padding-left: rem(160);
        }
        &:last-child {
            padding-right: rem(160);
        }

        .item-wrapper {

            display: flex;
            flex-direction: column;
            align-items: center;

            .icon {
                width: rem(40);
            }

            .tab-name {
                margin-top: rem(10);
                font-size: remF(24);
            }
        }

        &.is-active {
            color: $base-n;
        }

        &.non-active {
            color: rgba(217, 217, 217, 1);
        }
    }
}
