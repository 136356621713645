@import '../../../../styles/index.scss';

.container {
    background-attachment: local;
    // height: rem(777);
    width: 100vw;
    position: relative;

    .bgimg {
        object-fit: cover;
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        // opacity: 1;
    }

    .contentTop {
        position: relative;
        padding-bottom: rem(120);
        // top: 0;
        // left: 0;
        width: 100vw;
        // height: 100%;
        z-index: 2;
        background-color: transparent;

        .subTitle {
            width: $content-width;
            margin: rem(20) auto 0;
            font-size: remF(24);
            font-weight: normal;
            line-height: rem(40);
            letter-spacing: 0px;
            color: #808b98;
        }

        .content {
            width: $content-width;
            margin: rem(40) auto 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            // justify-content: space-between;
            column-gap: rem(19);
            align-items: center;
            align-content: space-between;

            .bank {
                position: relative;
                height: rem(148);
                width: rem(236);
                margin-bottom: rem(20);
                border-radius: rem(16);
                background-color: white;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.01);
            }
        }

        .desc {
            width: $content-width;
            margin: rem(12) auto 0;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0px;
            color: #979fad;
            white-space: pre-line;
        }
    }
}

.container1024 {
    .contentTop {
        padding-bottom: rem(80);

        .subTitle {
            width: $content-width1024;
            font-size: remF(14);
            line-height: rem(24);
        }

        .content {
            width: $content-width1024;
            margin: rem(28) auto 0;

            .bank {
                height: rem(120);
                width: rem(173);
                margin-bottom: 20px;
                border-radius: rem(16);
            }
        }

        .desc {
            width: $content-width1024;
            margin: rem(0) auto 0;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            white-space: pre-line;
        }
    }
}

:global {
    .bankDefaultImg {
        display: block;
        width: rem(220);
        height: rem(94);
        position: absolute;
        top: rem(30);
        left: rem(8);
    }

    .bankDefaultImg1024 {
        display: block;
        width: rem(168);
        height: rem(72);
        position: absolute;
        top: rem(24);
        left: rem(3);
    }

    .bank-hover-name {
        font-size: remF(24);
        font-weight: 600;
        line-height: rem(28);
        letter-spacing: 0px;
        color: #1e2329;
    }

    .bank-hover-name-1024 {
        font-size: remF(20);
        font-weight: 600;
        line-height: rem(28);
        letter-spacing: 0px;
        color: #1e2329;
    }

    .bank-hover-name-arrow {
        width: rem(26);
        height: rem(26);
    }

    .bank-hover-name-en {
        font-size: remF(22);
        font-weight: 600;
        line-height: rem(28);
        letter-spacing: 0px;
        color: #1e2329;
        margin-bottom: rem(8);
        text-align: center;
    }

    .bank-hover-body {
        margin-top: rem(16);
        font-size: remF(18);
        font-weight: normal;
        line-height: rem(20);
        letter-spacing: 0px;
        color: #1e2329;
    }

    .bank-hover-body-1024 {
        margin-top: rem(8);
        font-size: remF(16);
        font-weight: normal;
        line-height: rem(20);
        letter-spacing: 0px;
        color: #1e2329;
    }
}
