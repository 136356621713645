@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.container {
    position: fixed;
    bottom: rem(80);
    transition: right 0.5s;
    right: 0px;
    width: rem(112);
    height: auto;
    z-index: 21;
    padding-left: rem(16);
    .contactimg {
        width: rem(80);
        height: rem(80);
        display: block;
    }
    .top {
        display: block;
        cursor: pointer;
        margin-top: rem(24);
        width: rem(80);
        height: rem(80);

        border-radius: rem(80);
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #eaecef;
        box-shadow: 0px rem(4) rem(12) 0px rgba(29, 35, 76, 0.06);
        img {
            width: rem(40);
            height: rem(40);
            margin-top: rem(20);
            margin-left: rem(20);
        }
    }
}
:global {
    .custom-modal {
        width: 100%;
        height: 100%;
        background-color: rgba($color: black, $alpha: 0.4);
        position: fixed;
        z-index: 9999;
        left: 0;
        touch-action: none;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .assistant-modal {
            width: rem(600);
            padding-bottom: rem(80);
            margin: auto;
            background-color: white;
            border-radius: rem(16);
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            .assistant-modal-title {
                font-weight: 500;
                font-size: remF(36);
                line-height: rem(44);
                color: #1e2329;
                margin-top: rem(40);
            }
            .assistant-modal-img {
                width: rem(360);
                height: rem(360);
                margin-top: rem(36);
            }
            .assistant-modal-subtitle {
                margin-top: rem(30);
                font-weight: 400;
                font-size: remF(30);
                line-height: rem(44);
                width: calc(100% - 40rem / 75);
                margin-left: rem(20);
                color: #1e2329;
            }
            .assistant-modal-subtitle-wechat {
                margin-top: rem(12);
                font-weight: 400;
                font-size: remF(26);
                line-height: rem(40);
                white-space: pre-wrap;
                color: rgba($color: #474d57, $alpha: 0.7);
            }
            .assistant-modal-button {
                margin-top: rem(40);
                background-color: #ffd400;
                font-weight: 500;
                font-size: remF(30);
                line-height: rem(40);
                color: #1e2329;
                width: rem(360);
                height: rem(80);
                line-height: rem(80);
                border-radius: rem(80);
                text-align: center;
            }
        }
    }
}
