@import "../../../../styles/index.scss";

.card_wrap {
    margin-left: 50%;
    transform: translateX(-50%);
    width: rem(702);
    height: rem(393);
    margin-top: rem(55);
    display: inline-block;
    background: #2c2c2c;
    border-radius: 24px;
    position: relative;
    font-family: PingFang SC;
    .title {
        position: absolute;
        height: rem(34);
        left: rem(24);
        top: rem(32);
        font-style: normal;
        font-weight: 300;
        font-size: remF(24);
        line-height: remF(34);
        /* identical to box height, or 140% */

        color: rgba(255, 255, 255, 0.8);
    }
    .total-amount {
        position: absolute;
        width: 100%;
        height: rem(68);
        left: rem(24);
        top: rem(82);
        font-style: normal;
        font-weight: 600;
        font-size: remF(48);
        line-height: rem(67);
        /* identical to box height, or 140% */

        color: #ffffff;
        .compare {
            display: inline-block;
            margin-left: rem(15);
            font-weight: 300;
            font-size: remF(24);
            line-height: remF(34);
            color: #ff0000;
        }
    }
    .jump {
        position: absolute;
        left: rem(660);
        top: rem(160);

        color: #9ca2aa;
        width:rem(30);
        height:rem(30);
    }
    .assets-and-liabilities {
        position: absolute;
        left: rem(24);
        top: rem(182);
        display: flex;
        height: rem(136);
        width: 100%;
        .data {
            flex: 1;
            .type {
                height: rem(34);
                font-style: normal;
                font-weight: 300;
                font-size: rem(24);
                line-height: rem(34);
                color: rgba(255, 255, 255, 0.8);
            }
            .amount {
                margin-top: rem(16);
                height: rem(45);
                font-style: normal;
                font-weight: normal;
                font-size: remF(32);
                line-height: remF(45);
                color: #ffffff;
            }
            .wave {
                margin-top: rem(8);
                height: rem(34);
                font-style: normal;
                font-weight: 300;
                font-size: rem(24);
                line-height: rem(34);
                color: #ff0000;
            }
        }
    }
    .update-time {
        position: absolute;
        left: rem(24);
        top: rem(335);
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 300;
        font-size: remF(18);
        line-height: rem(25);
        /* identical to box height, or 140% */

        color: rgba(255, 255, 255, 0.5);
    }
}
