@import '../../styles/index.scss';
.wrapper {
    height:100%;
    overflow: scroll;
    .tab-wrapper {
        .tab-container {
            display: flex;
            .item {
                height: rem(45);

                font-family: PingFang SC;
                font-style: normal;
                font-weight: 300;
                font-size: remF(32);
                line-height: rem(45);
                /* identical to box height, or 140% */

                color: #ffffff;

                opacity: 0.4;
                margin-left: rem(48);
                transition: 0.2s;
            }
            .select-tab {
                font-weight: 600;
                opacity: 1;
                color: #ffc200;
            }
        }
        .bottom {
            width: rem(128);
            height: rem(5);

            background: #ffc200;
            border-radius: rem(28);
            margin-left: rem(48);
            margin-top: 1px;
            transition: 0.2s;
        }
        .bottom.optimization {
            transform: translateX(rem(176));
            width: rem(192);
        }
    }
    .content-wrapper {
        margin-top: rem(69);
        .line-chart {
            margin: 0 rem(48);
            .line-chart-title {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 600;
                font-size: remF(28);
                line-height: rem(39);
                /* identical to box height, or 139% */

                color: #fff9ed;
            }
            .line-chart-data {
                display: flex;
                margin-top: rem(48);
                .line-chart-data-time {
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 400;
                    font-size: remF(24);
                    line-height: rem(34);
                    color: #ffffff;
                }
                .line-chart-data-amount {
                    font-family: PingFang SC;
                    margin-left: rem(32);
                    font-style: normal;
                    font-weight: 400;
                    font-size: remF(24);
                    line-height: rem(34);
                    color: #ffc200;
                }
            }
            .line-chart-content {
                margin-top: rem(16);
                width: rem(648);
                height: rem(490);

                background: #1f1f1f;
            }
        }
    }
}
