@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';

.tech-contain-mobile {
    width: 100vw;
    height: rem(1424);
    background-color: #fbfbfb;
    position: relative;

    .tech-mobile-box {
        padding: 0 rem(32);
        margin-top: rem(32);
        height: rem(1164);

        .tech-content {
            width: 100%;
            height: rem(976);
            overflow: hidden;
            position: relative;

            .tech-content-bg {
                display: block;
                margin: 0 auto;
                text-align: center;
                width: 100%;
                height: rem(765);
                object-fit: cover;
            }

            .tech-fullfill {
                position: absolute;
                top: rem(360);

            }

            .tech-static-box {
                position: absolute;
                top: rem(140);
                // width: rem(606);
                // height: rem(606);
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                justify-content: center;

                .tech-static-item {
                    width: rem(606);
                    margin-bottom: rem(64);

                    .tech-static-item-num {
                        font-size: rem(96);
                        font-weight: bold;
                        line-height: rem(116);
                        text-align: center;
                        color: #1E2329;
                        margin-bottom: rem(10);
                    }

                    .tech-static-item-desc {
                        font-size: rem(26);
                        line-height: rem(32);
                        font-weight: 300;
                        text-align: center;
                        letter-spacing: 0px;
                        color: #1E2329;
                    }
                }
            }

            .tech-selectBar {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: rem(48);
                width: rem(648);
                height: rem(80);
                border-radius: rem(28);
                background: #FFFFFF;
                display: flex;
                align-items: center;

                .tech-selectBar-btn {
                    width: rem(158);
                    height: rem(64);
                    font-size: remF(26);
                    line-height: rem(64);
                    text-align: center;
                    cursor: pointer;
                    color: #707A8A;
                }

                .tech-selectBar-btn-active {
                    border-radius: 26px;
                    color: #1E2329;
                    background: #FFC612;
                }
            }

        }
    }
}