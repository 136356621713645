@import '../../../../styles/index.scss';
.container {
    position: fixed;
    bottom: 20vh;
    right: rem(24);
    display: flex;
    width: auto;
    height: auto;
    z-index: 21;
    .qrcode {
        width: rem(200);
        // height: rem(224);
        padding: rem(10);
        box-sizing: border-box;
        background-color: white;
        border: 1px solid #eaecef;
        text-align: center;
        border-radius: rem(10);
        box-shadow: 0px rem(4) rem(12) 0px rgba(29, 35, 76, 0.06);
        .qrimg {
            width: rem(180);
            height: rem(180);
        }
        .one {
            margin-top: rem(6);
            //font-family: MiSans-Normal;
            font-size: remF(16);
            font-weight: normal;
            line-height: rem(24);
            letter-spacing: 0px;
            color: #1e2329;
        }
        .two {
            //font-family: MiSans-Normal;
            font-size: remF(16);
            font-weight: normal;
            line-height: rem(20);
            transform: scale(0.9);
            letter-spacing: 0px;
            color: #474d57;
        }
    }
    .right {
        margin-left: rem(12);
        .contact {
            display: block;
            cursor: pointer;
            width: rem(56);
            height: rem(156);
            //font-family: MiSans-Normal;
            word-wrap: break-word;

            font-size: remF(16);
            font-weight: normal;
            line-height: rem(20);
            letter-spacing: 0px;
            color: #1e2329;

            border-radius: rem(28);
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #eaecef;
            box-shadow: 0px rem(4) rem(12) 0px rgba(29, 35, 76, 0.06);
            display: flex;
            flex-direction: column;
            align-items: center;
            .contacttext {
                width: rem(20);
                margin-top: rem(6);
            }
            .contactimg {
                width: rem(44);
                height: rem(44);
                margin-top: rem(6);
            }
        }
        .contactEn {
            height: rem(56);
            .contactimg {
                margin-top: 0;
                width: rem(56);
                height: rem(56);
            }
        }
        .top {
            display: block;
            cursor: pointer;
            margin-top: rem(16);
            width: rem(56);
            height: rem(56);

            border-radius: rem(56);
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #eaecef;
            box-shadow: 0px rem(4) rem(12) 0px rgba(29, 35, 76, 0.06);
            img {
                width: rem(32);
                height: rem(32);
                margin-top: rem(12);
                margin-left: rem(12);
            }
        }
    }
}
.container1024 {
    bottom: 20vh;
    right: rem(22);

    width: auto;

    .qrcode {
        width: rem(136);
        height: rem(188);
        padding: rem(4);
        .qrimg {
            width: rem(128);
            height: rem(128);
        }
        .one {
            margin-top: rem(4);
            font-size: remF(16);
            line-height: rem(22);
        }
        .two {
            margin-top: rem(2);
            font-size: remF(12);
            line-height: rem(20);
        }
    }
    .qrcodeEn {
        width: rem(160);
        height: rem(218);
        .qrimg {
            width: rem(152);
            height: rem(152);
        }
        .one {
            margin-top: rem(4);
            font-size: remF(14);
            line-height: rem(20);
        }
        .two {
            margin-top: rem(2);
            font-size: remF(14);
            line-height: rem(16);
        }
    }
    .right {
        margin-left: rem(12);
        .contact {
            width: rem(48);
            height: rem(144);

            font-size: remF(14);

            border-radius: rem(48);
            .contacttext {
                width: rem(20);
                margin-top: rem(4);
                text-align: center;
            }
            .contactimg {
                width: rem(40);
                height: rem(40);
                margin-top: rem(4);
            }
        }
        .contactEn {
            height: rem(48);
            .contactimg {
                margin-top: 0;
                width: rem(48);
                height: rem(48);
            }
        }
        .top {
            margin-top: rem(8);
            width: rem(48);
            height: rem(48);

            border-radius: rem(48);
            img {
                width: rem(24);
                height: rem(24);
                margin-top: rem(12);
                margin-left: rem(12);
            }
        }
    }
}
