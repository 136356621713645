@import '../../../styles/index.scss';

:global {
    .click_hold_value_flex {
        .am-flexbox-item {
            margin-left: 0px !important;
        }
    }
    .country_account_modal {
        .am-modal .country_account_modal .am-modal-transparent {
            width: rem(500) !important;
        }
        .am-modal-content {
            padding: 0px !important;
            background-color: $bg-card !important;
        }    
        .tips {
            color: $white-a;
            margin: rem(10) 0 0 rem(5);
            text-align: left;
        }
        
        .country_line {
            border-bottom: 1px solid $bgc-n;
            padding-bottom: rem(20);
            .current_color {
                color: $base-n !important;
            }
            // 模态框的国旗
            .country_flag {
                width: rem(80);
                height: rem(80);
                margin: rem(10) rem(20) 0 rem(30);
                position: relative;
                right: rem(25);
                top: rem(10);
            }
            .margin-left-20 {
                margin-left: rem(20);
            }
            .margin-left-10 {
                margin-left: rem(10);
            }
            .account_value {
                color: white;
                font-size: rem(30);                
                margin-top: rem(40);
            }
            .account_rate {
                color: $white-a;
                font-size: rem(30);
            }
        }
    }
}

.container {
    width: 100%;
    height: 100%;
    background-color: $bg-card;
    margin-bottom: rem(15);
    // border-radius: 10px;

    .bar {
        width: rem(150);
        border-radius: rem(20);
        height: rem(5);
        background-color: $base-n;
        position: relative;
        left: 40%;
    }

    .total_market_value {
        .title {
            padding: rem(30) 0 0 rem(30);
        }
        .number {
            font-size: rem(40);
            padding: rem(10) 0 rem(20) rem(30);
        }
    }

    .float_right {
        float: right;
    }

    .acount_unit {
        margin: rem(10) rem(25);
        color: white;
        line-height: 30px;
    }

    .acount_title {
        line-height: 20px;
        color: $white-a;
        margin: rem(10) rem(25);
    }

    .account_line {
        border-bottom: 1px solid $bgc-n;
        padding: rem(10) rem(5);
    }
    
    .hold_value {
        position: relative;
        line-height: 30px;
        .superscript {
            width: rem(38);
            position: absolute;
            bottom: rem(30);
        }    
        .superscript_bigger {
            width: rem(38);
            position: absolute;
            bottom: rem(20);
            left: rem(150);
        }
    }
    .click_button {
        width: rem(23);
        height: rem(23);
    }

    .cash_loan_title {
        background-color: #404040;
        height: 40px;
        line-height: 40px;
        margin-bottom: 1px;
        .title {
            padding-left: rem(25);
        }
        .cash {
            padding-right: rem(25);
        }
    }
   
    .account {
        float: right;
    }
}
