@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.body {
    background-image: linear-gradient(to bottom, white, rgba(white, 0));
    background-repeat: no-repeat;
    background-size: 100% rem(438);
    .advantage {
        display: flex;
        overflow-x: scroll;
        justify-content: space-between;
        margin-top: rem(32);
        box-sizing: border-box;
        padding-left: rem(32);
        padding-right: rem(8);
        padding-bottom: rem(20);
        .advantageItem {
            width: rem(580);
            border-radius: rem(16);
            height: rem(590);
            margin-right: rem(24);
            .topContent {
                border-top-left-radius: rem(16);
                border-top-right-radius: rem(16);
                background: linear-gradient(298deg, rgba(255, 212, 0, 0) 1%, #ffd400 100%);
                position: relative;
                width: rem(580);
                height: rem(240);
                .img {
                    height: rem(48);
                    width: rem(48);
                    position: absolute;
                    left: rem(32);
                    bottom: rem(32);
                }
                .index {
                    width: rem(48);
                    height: rem(48);
                    position: absolute;
                    right: rem(32);
                    bottom: rem(32);
                }
            }
            .bottomContent {
                border-bottom: 1px solid #eaecef;
                border-left: 1px solid #eaecef;
                border-right: 1px solid #eaecef;
                border-bottom-left-radius: rem(16);
                border-bottom-right-radius: rem(16);
                box-sizing: border-box;
                background-color: white;
                width: rem(580);
                height: rem(350);
                padding: rem(32);
                .bottomtitle {
                    font-size: remF(40);
                    font-weight: 600;
                    line-height: rem(56);

                    ul {
                        //font-family: MiSans-Normal;
                        margin-top: rem(24);
                        font-size: remF(28);
                        font-weight: normal;
                        line-height: rem(45);
                        letter-spacing: 0px;
                        color: #707a8a;
                        padding: 0;
                        padding-left: rem(20);
                        font-weight: 300;
                        li {
                            margin-bottom: rem(12);
                        }
                    }
                }
            }
        }
    }
}
.bodyEn {
    background-image: linear-gradient(to bottom, white, rgba(white, 0));
    background-repeat: no-repeat;
    background-size: 100% rem(438);
    background-color: #f7f7f8;
    .advantage {
        display: flex;
        overflow-x: scroll;
        justify-content: space-between;
        margin-top: rem(32);
        box-sizing: border-box;
        padding-left: rem(32);
        padding-right: rem(8);
        padding-bottom: rem(0);
        .advantageItem {
            width: rem(580);
            height: rem(644);
            border-radius: rem(16);
            margin-right: rem(24);

            .img {
                width: rem(580);
                height: rem(240);
                background-size: 100% 100%;
                font-size: remF(36);
                font-weight: 600;
                line-height: rem(40);
                letter-spacing: 0px;
                color: white;
                // padding-top: rem(159);
                // padding-left: rem(40);
                position: relative;
                .mask {
                    width: rem(580);
                    height: rem(120);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 10;
                }
                .content {
                    position: absolute;
                    left: rem(40);
                    top: rem(159);
                    z-index: 20;
                    span {
                        vertical-align: middle;
                    }

                    .line {
                        height: rem(24);
                        vertical-align: middle;
                        align-self: center;
                        width: rem(24);
                        margin-left: rem(10);
                        margin-right: rem(10);
                    }
                }
            }
            .bottomContent {
                border-bottom: 1px solid #eaecef;
                border-left: 1px solid #eaecef;
                border-right: 1px solid #eaecef;
                border-bottom-left-radius: rem(16);
                border-bottom-right-radius: rem(16);
                box-sizing: border-box;
                background-color: white;
                width: rem(580);
                height: rem(404);
                padding: rem(32) rem(24);
                .bottomtitle {
                    ul {
                        //font-family: MiSans-Normal;
                        font-size: remF(28);
                        font-weight: normal;
                        line-height: rem(40);
                        letter-spacing: 0px;
                        color: #707a8a;
                        padding: 0;
                        padding-left: rem(20);
                        font-weight: 300;
                        li {
                            margin-bottom: rem(12);
                        }
                    }
                }
            }
        }
    }
}
