@import '../../../../styles/index.scss';

.body {
    .imgContent {
        width: $content-width;
        margin: 0 auto;

        .lkAdvantageLine {
            width: rem(88);
            height: rem(10);
            margin-top: rem(120);
        }
    }

    .title {
        font-size: remF(40);
        font-weight: 600;
        line-height: rem(56);
        letter-spacing: 0px;
        width: $content-width;
        margin: 0 auto;
        padding-top: rem(10);
        color: #1e2329;
    }
}

.body1024 {
    .imgContent {
        width: $content-width1024;

        .lkAdvantageLine {
            margin-top: rem(80);
            width: rem(88);
            height: rem(10);
        }
    }

    .title {
        width: $content-width1024;
        font-size: remF(32);
        line-height: rem(40);
    }
}

.bodyEn {
    font-size: remF(48);
    font-weight: 250;
    line-height: rem(56);
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 0 auto;
    padding-top: rem(10);
    color: #1E2329;
}