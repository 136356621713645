@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.content {
    width: 100vw;
    background-color: #f7f7f8;

    .topTitle {
        margin: rem(20) rem(32) 0;
        //font-family: MiSans-Normal;
        font-weight: 300;
        font-size: remF(16);
        line-height: rem(26);
        letter-spacing: 0px;
        color: #707a8a;
        white-space: pre-wrap;
    }
    .titleDesc {
        width: calc(100% - rem(64));
        margin: rem(20) rem(32);
        font-size: remF(26);
        font-weight: normal;
        line-height: rem(40);
        letter-spacing: 0px;
        white-space: break-spaces;
        // height: rem(160);
    }
    .container1 {
        overflow: auto;
        padding-top: rem(64);
        .cardItem {
            padding-bottom: rem(64);
            .header {
                display: flex;
                align-items: center;
                .cardIcon {
                    width: rem(48);
                    height: rem(48);
                    flex-shrink: 0;
                }
                .sp {
                    height: 1px;
                    flex-grow: 1;
                    background-color: #d8d8d8;
                }
            }

            .titleContent {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: rem(40);
                .iconD {
                    flex: 0 0 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .icon {
                        width: rem(104);
                        height: rem(104);
                        background-color: #ffd400;
                        border-radius: rem(32);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: rem(48);
                            height: rem(48);
                        }
                    }
                    .text {
                        margin-top: rem(16);
                        font-size: remF(24);
                        font-weight: normal;
                        line-height: rem(32);
                        color: black;
                    }
                }
            }
        }
    }
    .container2 {
        overflow: auto;
        padding-bottom: rem(60);
        .cardContent {
            margin-top: rem(48);
            display: flex;
            flex-direction: column;
            align-items: center;
            .cardLeft {
                width: auto;
                position: relative;
                .cardLeftTop {
                    //font-family: MiSans-Demibold;
                    font-size: remF(40);
                    position: relative;
                    font-weight: 600;
                    line-height: rem(60);
                    letter-spacing: 1px;
                    color: #000000;
                    z-index: 40;
                }
                .cardLeftTopOne {
                    //font-family: MiSans-Demibold;
                    font-size: remF(40);
                    position: relative;
                    font-weight: 600;
                    line-height: rem(60);
                    letter-spacing: 1px;
                    color: #000000;
                    z-index: 40;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .cardLeftTopLine {
                    height: rem(16);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: rem(6);
                    z-index: 1;
                    background-color: rgba($color: #ffd400, $alpha: 0.3);
                }
            }
            .cardLeftBottom {
                margin-top: rem(48);
                display: flex;
                justify-content: center;
                .cardLeftBottomIcon {
                    width: rem(130);
                    height: rem(130);
                }
                .cardLeftBottomRight {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    margin-left: rem(24);
                    .cardLeftBottomName {
                        //font-family: MiSans-Demibold;
                        font-size: remF(36);
                        font-weight: 600;
                        line-height: rem(36);
                        letter-spacing: 0em;
                        color: #000000;
                    }
                    .cardLeftBottomTitle {
                        //font-family: MiSans-Normal;
                        margin-top: rem(20);
                        font-size: remF(26);
                        font-weight: normal;
                        line-height: rem(30);
                        letter-spacing: 0em;
                        color: #000000;
                    }
                }
            }
            .cardRight {
                width: 100%;
                margin-top: rem(40);
                .cardRightTop {
                    width: 100%;
                    height: rem(160);
                    border-radius: rem(16);
                    background-color: white;
                    border: 1px solid #f1f1f1;
                    box-shadow: 0px rem(4) rem(16) 0px rgba(0, 0, 0, 0.04);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 rem(43);
                    .cardRightTopLeft {
                        display: flex;
                        align-items: center;
                        //font-family: MiSans-Demibold;
                        line-height: rem(40);
                        letter-spacing: 0px;
                        color: #1e2329;
                        .cardRightTopLeftIcon {
                            width: rem(72);
                            margin-right: rem(20);
                            height: rem(72);
                        }
                        .cardRightTopOne {
                            font-size: remF(28);
                            font-weight: 400;
                        }
                        .cardRightTopTow {
                            font-size: remF(32);
                            font-weight: 600;
                            margin-top: rem(4);
                        }
                    }
                    .line {
                        width: 1px;
                        height: rem(120);
                        background-color: #f0f0f0;
                    }
                }

                .cardRightBottomItem {
                    .cardRightBottomItemTop {
                        //font-family: MiSans-Medium;
                        font-size: remF(30);
                        font-weight: 500;
                        line-height: rem(30);
                        margin-top: rem(48);
                        letter-spacing: 0px;
                        vertical-align: middle;
                        .block {
                            width: rem(14);
                            height: rem(14);
                            display: inline-block;
                            background-color: #ffd400;
                            vertical-align: middle;
                            margin-right: rem(16);
                            margin-bottom: rem(2);
                        }
                    }
                    .cardRightBottomItemDesc {
                        margin-top: rem(20);
                        //font-family: MiSans-Normal;
                        font-size: remF(26);
                        font-weight: 400;
                        line-height: rem(38);
                        letter-spacing: 0px;
                        color: #707a8a;
                    }
                }
            }
        }
    }
    .container3 {
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: rem(64);
        .cardLeft {
            margin-top: rem(48);
            width: auto;
            position: relative;
            .cardLeftTop {
                //font-family: MiSans-Demibold;
                font-size: remF(36);
                font-weight: 600;
                line-height: rem(48);
                letter-spacing: 1px;
                color: #1e2329;
                z-index: 20;
            }
            .cardLeftTopOne {
                //font-family: MiSans-Demibold;
                font-size: remF(36);
                font-weight: 600;
                line-height: rem(48);
                letter-spacing: 1px;
                color: #1e2329;
                z-index: 40;
                position: absolute;
                left: 0;
                top: 0;
            }
            .cardLeftTopLine {
                height: rem(16);
                position: absolute;
                left: 0;
                right: 0;
                bottom: rem(6);
                z-index: 10;
                background-color: rgba($color: #ffd400, $alpha: 0.3);
            }
        }
        .subTitle {
            color: #1e2329;
            font-weight: 400;
            font-size: remF(28);
            line-height: rem(40);
            margin: rem(11) rem(24) 0;
            letter-spacing: 0;
            text-align: center;
        }
        img {
            margin-top: rem(32);
            width: 100%;
            object-fit: contain;
            height: calc((100vw - 64rem / 75) / 686 * 765);
        }
        .button {
            width: rem(572);
            height: rem(80);
            background-color: #f7f7f8;
            border-radius: rem(80);
            // margin: rem(40) auto;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            padding: rem(8);
            .buttonItem {
                line-height: rem(64);
                height: rem(64);
                width: rem(180);
                //font-family: MiSans-Medium;
                font-size: remF(28);
                // font-weight: 500;
                letter-spacing: 0px;

                color: #707a8a;
                font-weight: 400;
                text-align: center;
                border-radius: rem(64);
            }
            .selectButton {
                background-color: white;
                box-shadow: 0px rem(4) rem(40) 0px rgba(159, 170, 187, 0.16);
                font-weight: 500;
                color: #1e2329;
            }
        }
    }
}
.contentEn {
    .container1 {
        overflow: auto;
        padding-bottom: rem(64);
        .cardItem {
            display: flex;
            align-items: flex-start;
            padding-top: rem(64);
            .cardIcon {
                width: rem(88);
                height: rem(88);
                flex-shrink: 1;
            }
            .titleContent {
                width: auto;
                margin-left: rem(40);

                .cardTitle {
                    //font-family: MiSans-Demibold;
                    font-size: remF(36);
                    font-weight: 600;
                    line-height: rem(45);
                    letter-spacing: 1px;
                    color: #000000;
                }
                .cardDesc {
                    //font-family: MiSans-Light;
                    font-size: remF(28);
                    margin-top: rem(24);
                    font-weight: 300;
                    line-height: rem(40);
                    letter-spacing: 0px;
                    color: #707a8a;
                    white-space: pre-wrap;
                }
            }
        }
    }
    .container2 {
        overflow: auto;
        padding-bottom: rem(60);
        .cardContent {
            margin-top: rem(64);
            display: flex;
            flex-direction: column;
            align-items: center;
            .cardLeft {
                width: auto;
                position: relative;
                text-align: center;
                white-space: pre-wrap;
                .cardLeftTop {
                    //font-family: MiSans-Demibold;
                    font-size: remF(40);
                    position: relative;
                    font-weight: 600;
                    line-height: rem(60);
                    letter-spacing: 1px;
                    color: #000000;
                    white-space: pre-wrap;
                    z-index: 40;
                }
                .cardLeftTopOne {
                    //font-family: MiSans-Demibold;
                    font-size: remF(40);
                    white-space: pre-wrap;
                    position: relative;
                    font-weight: 600;
                    line-height: rem(60);
                    letter-spacing: 1px;
                    color: #000000;
                    z-index: 40;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .cardLeftTopLine {
                    height: rem(16);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: rem(6);
                    z-index: 1;
                    background-color: rgba($color: #ffd400, $alpha: 0.3);
                }
            }
            .cardLeftBottom {
                margin-top: rem(48);
                display: flex;
                justify-content: center;
                .cardLeftBottomIcon {
                    width: rem(130);
                    height: rem(130);
                }
                .cardLeftBottomRight {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    margin-left: rem(24);
                    .cardLeftBottomName {
                        //font-family: MiSans-Demibold;
                        font-size: remF(36);
                        font-weight: 600;
                        line-height: rem(36);
                        letter-spacing: 0em;
                        color: #000000;
                    }
                    .cardLeftBottomTitle {
                        //font-family: MiSans-Normal;
                        margin-top: rem(20);
                        font-size: remF(26);
                        font-weight: normal;
                        line-height: rem(30);
                        letter-spacing: 0em;
                        color: #000000;
                    }
                }
            }
            .cardRight {
                width: 100%;
                margin-top: rem(48);
                .cardRightTop {
                    width: 100%;
                    height: rem(260);
                    border-radius: rem(16);
                    background-color: white;
                    border: 1px solid #f1f1f1;
                    box-shadow: 0px rem(4) rem(16) 0px rgba(0, 0, 0, 0.04);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: rem(42) rem(24);
                    .cardRightTopLeft {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        //font-family: MiSans-Demibold;
                        line-height: rem(40);
                        letter-spacing: 0px;
                        color: #1e2329;
                        .cardRightTopLeftIcon {
                            width: rem(72);
                            margin-right: rem(0);
                            height: rem(72);
                        }
                        .cardRightTopOne {
                            margin-top: rem(24);
                            font-size: remF(27);
                            font-weight: 300;
                            line-height: rem(40);
                        }
                        .cardRightTopTow {
                            font-size: remF(29);
                            font-weight: 600;
                            margin-top: rem(0);
                            line-height: rem(40);
                        }
                    }
                    .line {
                        width: 1px;
                        height: rem(120);
                        background-color: #f0f0f0;
                    }
                }

                .cardRightBottomItem {
                    .cardRightBottomItemTop {
                        //font-family: MiSans-Medium;
                        font-size: remF(32);
                        font-weight: 500;
                        line-height: rem(40);
                        margin-top: rem(44);
                        letter-spacing: 0px;
                        vertical-align: middle;
                        .block {
                            width: rem(14);
                            height: rem(14);
                            display: inline-block;
                            background-color: #ffd400;
                            vertical-align: middle;
                            margin-right: rem(16);
                            margin-bottom: rem(2);
                        }
                    }
                    .cardRightBottomItemDesc {
                        margin-top: rem(20);
                        //font-family: MiSans-Normal;
                        font-size: remF(28);
                        font-weight: 400;
                        line-height: rem(40);
                        letter-spacing: 0px;
                        color: #707a8a;
                    }
                }
            }
        }
    }
    .container3 {
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: rem(64);
        .cardLeft {
            margin-top: rem(48);
            width: auto;
            position: relative;
            .cardLeftTop {
                //font-family: MiSans-Demibold;
                font-size: remF(36);
                font-weight: 600;
                line-height: rem(48);
                letter-spacing: 1px;
                color: #1e2329;
                z-index: 20;
            }
            .cardLeftTopOne {
                //font-family: MiSans-Demibold;
                font-size: remF(36);
                font-weight: 600;
                line-height: rem(48);
                letter-spacing: 1px;
                color: #1e2329;
                z-index: 40;
                position: absolute;
                left: 0;
                top: 0;
            }
            .cardLeftTopLine {
                height: rem(16);
                position: absolute;
                left: 0;
                right: 0;
                bottom: rem(6);
                z-index: 10;
                background-color: rgba($color: #ffd400, $alpha: 0.3);
            }
        }
        .subTitle {
            margin-top: rem(11);
            color: #1e2329;
            font-weight: 400;
            font-size: remF(28);
            line-height: rem(40);
        }
        img {
            margin-top: rem(32);
            width: 100%;
            object-fit: contain;
            height: calc((100vw - 64rem / 75) / 686 * 765);
        }
        .button {
            width: rem(646);
            height: rem(80);
            background-color: #f7f7f8;
            border-radius: rem(64);
            // margin: rem(40) auto;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            padding: rem(8);
            .buttonItem {
                line-height: rem(64);
                height: rem(64);
                width: rem(210);
                //font-family: MiSans-Medium;
                font-size: remF(26);
                // font-weight: 500;
                letter-spacing: 0px;

                color: #707a8a;
                font-weight: 500;
                text-align: center;
                border-radius: rem(64);
            }
            .selectButton {
                background-color: white;
                box-shadow: 0px rem(4) rem(40) 0px rgba(159, 170, 187, 0.16);
                font-weight: 400;
                color: #1e2329;
            }
        }
    }
}

:global {
    .support-collapse {
        text-align: left;
        overflow: hidden;
        border-radius: rem(16);
        margin: rem(32) rem(32) 0;

        // box-shadow: 0 rem(16) rem(16) rgba($color: #474d57, $alpha: 0.1);
        .support-collapse-header {
            background-color: #ffd400;
            height: rem(184);
            display: flex;
            align-items: center;
            padding: rem(52) rem(48);
            background-image: url('../.././mobile_images/bg_wave_top_card.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            .icon {
                width: rem(80);
                height: rem(80);
            }
            .arrow-container {
                border-radius: rem(20);
                flex-shrink: 0;
                overflow: hidden;
                box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.2);
            }
            .arrow {
                width: rem(40);
                height: rem(40);
                transition: transform 0.2s cubic-bezier(0.42, 0, 0.63, 1);
            }
            .support-collapse-header-title {
                font-size: remF(36);
                font-weight: 600;
                line-height: rem(45);
                letter-spacing: 0px;
                white-space: pre-wrap;
            }
            .support-collapse-header-divider {
                background-color: #1e2329;
                margin-left: rem(32);
                width: rem(1);
                height: rem(80);
            }
            .support-collapse-header-subtitle {
                flex-grow: 1;
                margin-left: rem(32);
                font-size: remF(32);
                font-weight: 400;
                line-height: remF(48);
                white-space: pre-wrap;
            }
            .support-collapse-header-en {
                .support-collapse-header-title {
                    font-size: remF(44);
                    font-weight: 600;
                    line-height: rem(46);
                    letter-spacing: 0px;
                }
                .support-collapse-header-subtitle {
                    font-size: remF(28);
                    font-weight: 400;
                    margin-top: rem(12);
                    line-height: remF(36);
                    white-space: pre-wrap;
                    margin-left: rem(0);
                }
            }
        }
        .support-collapse-content {
            overflow: hidden;
            transition: max-height 0.3s cubic-bezier(0.42, 0, 0.63, 1);
            padding: 0 rem(38);
            background-color: white;
            > div {
                overflow: auto;
            }
        }
        .support-collapse-content-none {
            padding: 0 !important;
        }
    }
}
