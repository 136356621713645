@import '../../../styles/index.scss';

.dashbord-wrapper {
    padding: rem(64) rem(48) rem(694) rem(48);
    .title {
        height: rem(48);

        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: remF(28);
        line-height: rem(48);
        /* identical to box height, or 171% */

        color: #fff9ed;
    }
    .container {
        width: rem(654);
        height: rem(490);
    }
    .warning {
        height: rem(34);
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: remF(24);
        line-height: rem(34);
        /* identical to box height, or 142% */
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        margin-top: rem(3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
