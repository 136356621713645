@import '../../../../styles/index.scss';
.content {
    width: 100vw;
    background-color: #fbfbfb;

    .stickTop {
        height: rem(234);
        width: 100%;

        .downIcon {
            width: 100%;
            height: rem(128);
            position: absolute;
            left: 0;
            bottom: -10px;
        }
        .stickbg {
            background-color: #fbfbfb;
            width: 100%;
            // height: rem(272);
            padding-top: rem(80);
            box-sizing: border-box;
            .containerImg {
                width: $content-width1024;
                margin: 0 auto;
                .lkAdvantageLine {
                    width: rem(72);
                    height: rem(8);
                }
            }

            .title {
                //font-family: MiSans-Demibold;
                font-size: remF(32);
                font-weight: 600;
                line-height: rem(40);
                letter-spacing: 0px;
                width: $content-width1024;
                margin: 0 auto;
                color: #1e2329;
            }
            .topTitle {
                width: $content-width1024;
                margin: rem(10) auto 0;
                //font-family: MiSans-Normal;
                font-weight: 300;
                font-size: remF(14);
                line-height: rem(20);
                letter-spacing: 0px;
                color: #707a8a;
                white-space: pre-wrap;
            }
        }

        .topIcon {
            width: 100%;
            height: rem(128);
        }
    }

    .container {
        width: $content-width1024;
        // position: absolute;
        margin: 0 auto;
        // height: rem(1320);
        padding-bottom: rem(120);
        // top: 0;
        // z-index: 4;
        .title {
            //font-family: MiSans-Demibold;
            font-size: remF(28);
            font-weight: 600;
            line-height: rem(40);
            color: #1e2329;
            width: 100%;
            text-align: center;
        }
        .desc {
            margin-top: rem(8);
            width: 100%;
            text-align: center;
            //font-family: MiSans-Light;
            font-size: remF(20);
            font-weight: 300;
            line-height: rem(30);
            letter-spacing: 0px;
            color: #1e2329;
        }
        .cardContent {
            height: rem(330);
            width: $content-width1024;
            margin: 0 auto;
        }
    }
    .container1 {
        .cardContent {
            margin-top: rem(24);
            display: flex;
            justify-content: space-between;
            .cardItem {
                width: rem(301);
                height: 100%;
                // background-image: url('../../../../images/service-card-bg.png');
                background-position: 0 0;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-attachment: local;
                background-color: white;
                padding: rem(48) rem(24);
                border-radius: rem(12);
                display: flex;
                flex-direction: column;
                .cardIcon {
                    width: rem(40);
                    height: rem(40);
                    margin-left: rem(16);
                }
                .cardTitle {
                    //font-family: MiSans-Demibold;
                    font-size: remF(20);
                    font-weight: normal;
                    line-height: 26px;
                    letter-spacing: 1px;
                    color: #000000;
                    flex-grow: 1;
                }
                .descContent {
                    //font-family: MiSans-Light;
                    display: flex;
                    flex-wrap: wrap-reverse;
                    row-gap: rem(12);
                    column-gap: rem(12);
                    font-size: remF(16);
                    margin-top: rem(20);
                    font-weight: 300;
                    line-height: rem(32);
                    letter-spacing: 0px;
                    color: #707a8a;
                    white-space: break-spaces;
                    .descItem {
                        width: calc(33% - 8px);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .descIcon {
                            width: rem(34);
                            margin-bottom: rem(12);
                        }
                        font-size: remF(18);
                        font-weight: normal;
                        line-height: 28px;
                    }
                }
            }
            .cardItemEn {
                .cardTitle {
                    line-height: rem(26);
                    margin-top: rem(20);
                    // font-family: Barlow-SemiBold;
                }
                .cardDesc {
                    margin-top: rem(12);
                    line-height: rem(24);
                    font-size: remF(16);
                    // font-family: Barlow-Light;
                    ul li {
                        margin-bottom: rem(4);
                        margin-left: rem(16);
                    }
                }
                padding: rem(20) rem(20) 0;
            }
        }
        .cardContentEn {
            height: rem(408);
        }
    }
    .container2 {
        // top: rem(800);
        .cardContent {
            margin-top: rem(24);
            display: flex;
            justify-content: space-between;
            .cardLeft {
                width: rem(301);
                height: 100%;
                // background-image: url('../../../../images/service-card-bg.png');
                background-position: 0 0;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-attachment: local;
                background-color: #ffd400;
                padding: rem(24);
                border-radius: rem(12);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .cardLeftTop {
                    //font-family: MiSans-Demibold;
                    font-size: remF(20);
                    font-weight: 600;
                    line-height: rem(40);
                    letter-spacing: 1px;
                    color: #000000;
                }
                .cardLeftBottom {
                    .cardLeftBottomIcon {
                        width: rem(100);
                        height: rem(100);
                    }
                    .cardLeftBottomName {
                        //font-family: MiSans-Demibold;
                        font-size: remF(24);
                        font-weight: 600;
                        line-height: rem(32);
                        letter-spacing: 0em;
                        color: #000000;
                        margin-top: rem(24);
                    }
                    .cardLeftBottomTitle {
                        //font-family: MiSans-Normal;
                        margin-top: rem(6);
                        font-size: remF(16);
                        font-weight: normal;
                        line-height: rem(24);
                        letter-spacing: 0em;
                        color: #000000;
                    }
                }
            }
            .cardRight {
                width: rem(623);
                height: rem(330);
                .cardRightTop {
                    width: 100%;
                    height: rem(80);
                    border-radius: rem(12);
                    background-color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 rem(32);
                    .cardRightTopLeft {
                        display: flex;
                        align-items: center;
                        //font-family: MiSans-Demibold;
                        font-size: remF(20);
                        font-weight: 600;
                        line-height: rem(40);
                        letter-spacing: 0px;
                        color: #1e2329;
                        .cardRightTopLeftIcon {
                            width: rem(48);
                            margin-right: rem(16);
                            height: rem(48);
                        }
                    }
                    .line {
                        width: 1px;
                        height: rem(64);
                        background-color: #f0f0f0;
                    }
                }
                .cardRightBottom {
                    width: 100%;
                    margin-top: rem(20);
                    height: rem(230);
                    background-color: white;
                    border-radius: rem(12);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-content: space-between;
                    padding: rem(16) rem(10);
                    .cardRightBottomItem {
                        width: calc(50% - 10px);
                        height: auto;
                        .cardRightBottomItemTop {
                            //font-family: MiSans-Medium;
                            font-size: remF(16);
                            font-weight: 500;
                            line-height: rem(24);
                            letter-spacing: 0px;
                            .block {
                                width: rem(8);
                                height: rem(8);
                                display: inline-block;
                                background-color: #ffd400;
                                vertical-align: middle;
                                margin-right: rem(10);
                                margin-bottom: rem(2);
                            }
                        }
                        .cardRightBottomItemDesc {
                            margin-top: rem(8);
                            //font-family: MiSans-Normal;

                            font-size: remF(12);
                            font-weight: 300;
                            line-height: rem(20);
                            letter-spacing: 0px;
                            color: #707a8a;
                        }
                    }
                }
            }
        }
    }
    .containerEn2 {
        .cardContent {
            height: rem(408);
            .cardLeft {
                width: rem(230);
                .cardLeftTop {
                    //font-family: MiSans-Demibold;
                    font-size: remF(20);
                    line-height: rem(30);
                }
                .cardLeftBottom {
                    .cardLeftBottomIcon {
                        width: rem(100);
                        height: rem(100);
                    }
                    .cardLeftBottomName {
                        //font-family: MiSans-Demibold;
                        font-size: remF(18);
                        line-height: rem(32);
                    }
                }
            }
            .cardRight {
                width: rem(694);
                .cardRightTop {
                    height: rem(72);
                    padding: 0 rem(24);
                    .cardRightTopLeft {
                        display: flex;
                        align-items: center;
                        //font-family: MiSans-Demibold;
                        font-size: remF(16);
                        font-weight: 400;
                        line-height: rem(24);
                        letter-spacing: 0px;
                        color: #1e2329;
                        .cardRightTopLeftIcon {
                            width: rem(48);
                            margin-right: rem(10);
                            height: rem(48);
                        }
                    }
                    .line {
                        width: 0px;
                        //     height: rem(120);
                        //     background-color: #f0f0f0;
                    }
                }
                .cardRightBottom {
                    height: rem(316);
                    padding: rem(20) rem(20);

                    .cardRightBottomItem {
                        width: calc(50% - 10px);
                        height: auto;
                        .cardRightBottomItemTop {
                            //font-family: MiSans-Medium;
                            font-size: remF(16);
                            font-weight: 600;
                            line-height: rem(24);
                            color: #1a1a1a;
                            .block {
                                width: rem(8);
                                height: rem(8);
                                display: inline-block;
                                background-color: #ffd400;
                                margin-right: rem(6);
                                margin-bottom: rem(2);
                            }
                        }
                        .cardRightBottomItemDesc {
                            margin-top: rem(6);
                            // font-family: Source Han Sans CN;

                            font-size: remF(12);
                            font-weight: 300;
                            line-height: rem(20);
                            color: #707a8a;
                        }
                    }
                }
            }
        }
    }
    .container3 {
        // padding-bottom: rem(80);
        .cardContent {
            height: rem(380);
            width: rem(958);
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .button {
            width: rem(404);
            height: rem(56);
            background-color: #edeff4;
            border-radius: rem(56);
            margin: rem(24) auto 0;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            padding: rem(6);
            .buttonItem {
                line-height: rem(44);
                height: rem(44);
                width: rem(128);
                //font-family: MiSans-Medium;
                font-size: remF(16);
                // font-weight: 500;
                letter-spacing: 0px;

                color: #707a8a;

                text-align: center;
                border-radius: rem(44);
            }
            .selectButton {
                background-color: white;
                font-weight: normal;
                color: #1e2329;
            }
        }
    }
    .containerEn3 {
        .button {
            width: rem(464);
            height: rem(56);
            .buttonItem {
                width: rem(148);
            }
        }
    }
    .noInbody {
        position: absolute;
        top: rem(0);
    }
    .noneDisplay {
        display: none;
    }
}
